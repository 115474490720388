import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: {
    ordinalAddress: '',
    paymentAddress: '',
    points: null,
    assetUpdated: null,
    assets: [],
    userName: ''
  },
  token: null,
  posts: [],
  ordinalAddress: '',
  paymentAddress: '',
  discord: {
    discordId: '',
    discordName: '',
    discordDiscriminator: '',
  }
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAddresses: (state, action) => {
      state.ordinalAddress = action.payload.ordinalAddress;
      state.paymentAddress = action.payload.paymentAddress;
    },
    setLogin: (state, action) => {
      state.user = action.payload.user;
    },
    setUserName: (state, action) => {
      state.user.userName = action.payload.userName;
    },
    setDiscord: (state, action) => {
      state.discord = action.payload.discord;
    },
    logoutDiscord: (state) => {
      state.discord = {
        discordId: '',
        discordName: '',
        discordDiscriminator: '',
      };
    },
    setLogout: (state) => {
      state.user = {
        ordinalAddress: '',
        paymentAddress: '',
        ethAddress: '',
        points: null
      };
    },
    setPosts: (state, action) => {
      state.posts = action.payload.posts;
    },
    setPost: (state, action) => {
      const updatedPosts = state.posts.map((post) => {
        if (post._id === action.payload.post._id) return action.payload.post;
        return post;
      });
      state.posts = updatedPosts;
    },
  },
});

export const { setAddresses, setLogin, setDiscord, logoutDiscord, setLogout, setUserName } =
  authSlice.actions;
export default authSlice.reducer;
