import React, { useState, useEffect } from 'react';

function Timer(props) {
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  function calculateTimeLeft() {
    const difference = +new Date(props.endTime) - +new Date();

    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        hours: Math.floor(difference / (1000 * 60 * 60) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    } else {
      timeLeft = {
        hours: 0,
        minutes: 0,
        seconds: 0,
      };
    }

    return timeLeft;
  }

  useEffect(() => {
    const timerId = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timerId);
  }, []);

  const formattedTimeLeft = `${timeLeft.hours < 10 ? '0' : ''}${timeLeft.hours}:${
    timeLeft.minutes < 10 ? '0' : ''}${timeLeft.minutes}:${
    timeLeft.seconds < 10 ? '0' : ''}${timeLeft.seconds}`;

  const isTimeOver = timeLeft.hours === 0 && timeLeft.minutes === 0 && timeLeft.seconds === 0;
  const serverName = props.serverName;

  return (
    <React.Fragment>
      <div
        style={{
          marginTop: 10,
          display: 'flex',
          justifyContent: 'center',
          fontSize: props.isMobile ? '20px' : '30px',
        }}
      >
        Event Hosted By {serverName}
      </div>
      <div
        style={{
          color: isTimeOver ? 'red' : 'black',
          marginTop: 10,
          display: 'flex',
          justifyContent: 'center',
          fontSize: '25px',
        }}
      >
        Time Left: {formattedTimeLeft}
      </div>
    </React.Fragment>
  );
}

export default Timer;