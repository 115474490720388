const env = {
    1: 'dev',
    2: 'staging',
    3: 'prod'
}

export const defaultInscriptionIds = [
    // doge
    '07d4df1c07f73cd374a989734e882cb638fa60a1ec1a5b98138df417c87468cbi0',
    '9b2cfa39a0b4980004084830c65e751b8a8202964b2596f45696df518e61fc9ai0',
    '667083b54c06646962ec1089e8e73ebf9c44d4538dcdf0309740c5dc716e509ai0',
    // robot
    '5011a458a5a3d9ed3ae80ca39f984f51b95eca54621424cabebb4e44c1c2037ai0',
    'b4720117481d6243157edd622199263741482aa8e92ca95a13b2d8231fb6b7e0i0',
    'e79d547d8b516cddfd71b18fab1e5b3400be2ed03725421230ef7ada36ab8b12i0',
    // bitcoinpunks
    '03190afb329844abc23145a76025dc8251c9f906d68cb0a86ac9cb9c79aa3f46i0',
    '6ad86e218f9969683437cc411a028a46a59ca1d6df623535383028464ddc5e75i0',
    '9bea7834f04b539348aec776e46da6b9c160dfd5f6f207d575a02eb40332ac37i0',
    // bicoinbulls
    'e4fdd09189c80dabd18928cb4c7c58928a79c05331d398761d3c64a2fc3c41adi0',
    '9fbc3c5d6fb42198b5fc743d0fae1cd4ad8280dd735afd54907c54a7179f95fai0',
    '13c0df1972e707772638003ad47faa915c4f8cc2f13890b7f01a1c7acdbae327i0',
    // Ordibots
    '5260dda21a3982b0fd0b6a81d0330124db68f5187720aefa904846b3b0a0cb7bi0',
    '1a7b99a1ab0c96e23921c410809d33d974f8bd9dfe66045bf8dfe2885c624855i0',
    '64dab1e424fd18f4af4c1f238a5ba92f61632b0089893591191c6468884b3462i0',
    '91e00c1061c47fd9b43cdf03a4f987b432f21a774705c73700d64aba1485b958i0',
    // Ordiblocks
    'a92563aeb101c9239942b6517cb639da65675c529357f3d44232c0e3e5edcdbbi0',
    //toxinstemplar
    '3b41d9830f61cca8711ffdee90d8355e8ece702d59d494d0ad40db0ea295f6eai0',
    'e7326302b52fed371c0cd74c4f308665348042dd8142a8a9df96936f1a8d95b4i0',
    'af8d6b524da64684aff41fe125cc16943e9e3371da6265d33c9b204c0c4c6553i0',
    //satoshit
    '2182d01ef13870830fbf7263f0f1aa32a25670695253f841bb90973591517d35i0',
    '8748ed75912f06d43bfc8d35ab78f76f0815418c826486127d7a673c06af670bi0',
    '4f8266e0626345a2aa2a1cc17d634f186b86cfb23362b13d5c7f4b4943780c2ci0',
    '25eae4a9059341af2392b3dfd416789a127ff5b08b108d5c43d72584ef320e1fi0',
    '3fdf7b62746c7d68311cc0816a3120b27e33aaa94053d045daa8c6399b20f29ai0',
    'ce5c2708c1d6de0e1e09875a389c28bbf091a3ad3cf32ec1abf91ebc0166976fi0',
    'c5359a74ced1f5afae0044e4b7159d4f4f73e17eb07531e79ccdd819bbb19ce7i0',
    '09c255bc7387fcb72769307c43e9e66990e8331670f34ffd0dc0391704efdbeei0',
    '52ff7f42c5dc95ab19b5356b1b366bfea27844b40b5e2bc87b20346841dc8109i0',
    'f69e293094c68f7c0f2021f41e447036b8e5367141be7535006d3ac5d3525ddbi0',
    'c27410cc59ff09cef1f2200300ddb026afbfdc980b8139f57205471f56c93976i0',
    '935a54c474a6f14f4a0914dd6748f82ef5c6aca76da973ee80bc7783163ef278i0',
    'f720c0c8224a5f31ee9a31c5201d728007d21cc5a6231df10046905e307295d0i0',
    'c3b9b65aeeeae08d3337770011d0e199d721eb3757abca9e66c93d3baffab96ci0',
    //bapc
    '92ee07dd5cdd6d5e2c99bc4648830e4f0cace1efb3499e5d5b42b03a03a81a0bi0',
    'af39674c7c3f0ac44d6691c45c0309b8e6618f23e6f787127a16505f36011499i0',
    '53c9b01e038684cdf912f6f3edba627a6210b4c16725bb2a82602b634cab9745i0',
    //Ordinal Fomojis
    'f79b7180eb873f69a9de35b7faacccd3a69a3d91237fc75aaf879cad3d0d0021i0',
    'b76fb47892d05593a81d2bd7f2137e43632346bc2e282908d4fa11772155f031i0',
    'f4eeebfb69f1c8ea81bc0c06ee6347ca885d2072ae0bf711b87512414b09f5c7i0',
    'f5c5220f179b15c3ba04bfb43a3c949f7fd4734dfacf0c45fc4a01f876e708f0i0',
    //Anons
    'be8b5e71d458fda6c52a58bbdff023e141d0f22186e13d8e88a78ac7172dfd40i0',
    'c8161e7c3c339cc0dd3e7a4e8c30988e22f0200f54a08bc836c0ba2328c6ad1fi0',
    '695de305e065a55a2ffee0365f48eb10ca56ff44fee4439992726a169dd63142i0',
    'af138ee7a5286ea296fb0d3e845d567b59086e01a5970be36de321b2671fce61i0',
    '719adbd72cec12cc6523a67a3ecdef215f6ff874538fe369480a873f64996670i0',
    //pomb
    '8403d2c2655644cbd7d4973b55a290d281585eedb9bc97dd5589e9999831f494i0',
    '7133f143c62f0deaa88019122cfbf213dd66fb9609a1d151980f36470e3018abi0',
    'b199f866df0a9ac2967bba7f12bd3e1c580ba7c611d68329b6db7ec5f0d9c344i0',
    //npc
    '55a208d3f6f677539a7edb075616125c7033059e00d0632303df3b03902a97cfi0',
    '7049177d3a34b7e5ab4d573b70c3d1b175fa7b77c45f7f99f98ff9faacfea319i0',
    '68b5c667d86b46c7b64cf7d753fb5ea51ea6f8a35381980f46abe86783da11eai0',
    'd6b2478ca24306d15c452ae00e89511d78e98607fb6f0fdc2494d134bbc4af9ci0',
    '48df3f4f80ac9fa682c5d27a5578c1fd13a2bea158fb5fed77dbf432615f7f3di0',
    //aperunners
    '2cd9d44b324efd557d2aa6fee73f655558f77bd85642862b668d73f567961af9i0',
    'b31bde90d6f5e6b4a7b291ea30afb774e2c34407907cab817192f70395f54e3ei0',
    'd8bb36bb3045a8a3eb7c3f68e94c23e4514e3f262e53c7999d4192022ef875c0i0',
    '4276c81571461c9f7cf04070ba2c36e4b4b3c9dd785a5872e5ae331b1770aaf8i0',
    //tribeordinals
    '8baf2ba4ab56f3eacfff29cb99c58538930373cdbbed8d14c93a6293f397e0bfi0',
    //skelly
    '85b7d677607a6652dbb70e7cba3e3e35f5845ead1b3c399fa2b6547f254bf2bbi0',
    '7dcbba3ce4d704787fc78b820b6f154dcba2f2f64b6c76b9aec61cc890e331b6i0',
    '2ad6cc3124d4847b51d2892b884bd9c91736a09fec4f48726f2bc2b2969b11f6i0',
    //bob
    '745ca70fc6f5224ffcdc78b3487475e7be55a8c05290d2cb62e8e27f89cd752ci0',
    '75ba66c3ed2437ddf5186e06f7128d9680f8fad051885cc7ca29e37bdb21012fi0',
    'f4a4cfa36d55c56ce2e1d08166c11f01a9d055dd494b8bcaadc9b7a504293934i0',
    '02742f30b90da4a6b4e4f3b9097ed03bb110f9382a97048548eada2ac085bc5fi0',
    '1d89e25708ffa5a5591ebe4020d49d341e3ca52783d66de99d49d92cf4a259e5i0',
    //buds
    'bbd1924449f5e201d8e47c7a04e29799cd88649504f9a90baa028835c7981e14i0',
    //taruguitos
    '69846620d38642f4ca0d1b8c34ca558e425f9850aeae9cd5745d40b433373388i0',
    '0adb2e008298a31ad7f828fc1f212a6ba329c257cd457376edd365a7acd9af76i0',
    '69dfb724dfe6392d360d83a15b0c2a9a28028c1117257b4d2090f6198621434ci0',
    '8dbb12017d16bcec1073f3cb5c392ef2ea81cefd0c3694142ba3ab485eb9d8aci0',
    '2e0b60a83522b3be5ea34de5d44c9a38f30dc8f478e9416c76cf497725d9b1bfi0',
    // Trial
    '0',
];

export const defaultSelected = {
    // collection: {
    //     name: 'dogepunks',
    //     label: 'DogePunks',
    //     size: 10000,
    //     order: 1
    // },
    // character: 'doge_1786_532510_07d4df1c07f73cd374a989734e882cb638fa60a1ec1a5b98138df417c87468cbi0',
    collection: {
        name: 'toshiorder',
        label: 'The Toshi Order',
        size: 5,
        order: 40
    },
    character: 'toshiorder_1254_35077838_6d4a5f65d8e182d478f0e8256d238e89504fa75d75ddbc6d1ed423c9ff6ca82di0',
}


export const defaultCharacters = {
    dogepunks: ['doge_1786_532510_07d4df1c07f73cd374a989734e882cb638fa60a1ec1a5b98138df417c87468cbi0',
        'doge_7464_527363_9b2cfa39a0b4980004084830c65e751b8a8202964b2596f45696df518e61fc9ai0',
        'doge_7215_528838_667083b54c06646962ec1089e8e73ebf9c44d4538dcdf0309740c5dc716e509ai0'],
    ordinalrobots: [
        "ordinalrobots_103_1051654_5011a458a5a3d9ed3ae80ca39f984f51b95eca54621424cabebb4e44c1c2037ai0",
        "ordinalrobots_108_1051694_b4720117481d6243157edd622199263741482aa8e92ca95a13b2d8231fb6b7e0i0",
        "ordinalrobots_388_1093899_e79d547d8b516cddfd71b18fab1e5b3400be2ed03725421230ef7ada36ab8b12i0",
    ],
    bitcoinpunks: [
        'bitcoinpunks_3298_17287_03190afb329844abc23145a76025dc8251c9f906d68cb0a86ac9cb9c79aa3f46i0',
        'bitcoinpunks_6781_28487_6ad86e218f9969683437cc411a028a46a59ca1d6df623535383028464ddc5e75i0',
        'bitcoinpunks_9111_23032_9bea7834f04b539348aec776e46da6b9c160dfd5f6f207d575a02eb40332ac37i0'
    ],
    bitcoinbandits: [
        'bitcoinbandits_0_0_0'
    ],
    bitcoinbulls: [
        'bitcoinbulls_89122_89122_13c0df1972e707772638003ad47faa915c4f8cc2f13890b7f01a1c7acdbae327i0',
        'bitcoinbulls_90991_90991_e4fdd09189c80dabd18928cb4c7c58928a79c05331d398761d3c64a2fc3c41adi0',
        'bitcoinbulls_86830_86830_9fbc3c5d6fb42198b5fc743d0fae1cd4ad8280dd735afd54907c54a7179f95fai0',
    ],
    ordibots: [
        'ordibots_69_8699203_5260dda21a3982b0fd0b6a81d0330124db68f5187720aefa904846b3b0a0cb7bi0',
        'ordibots_180_8699209_1a7b99a1ab0c96e23921c410809d33d974f8bd9dfe66045bf8dfe2885c624855i0',
        'ordibots_279_8718419_64dab1e424fd18f4af4c1f238a5ba92f61632b0089893591191c6468884b3462i0',
        'ordibots_281_8763271_91e00c1061c47fd9b43cdf03a4f987b432f21a774705c73700d64aba1485b958i0',
    ],
    ordiblocks: [
        'ordiblocks_90_9848841_a92563aeb101c9239942b6517cb639da65675c529357f3d44232c0e3e5edcdbbi0',
    ],
    ordinaldragons: [
        'ordinaldragons_0_1_0',
        'ordinaldragons_1_2_0',
        'ordinaldragons_2_3_0',
        'ordinaldragons_3_4_0',
        'ordinaldragons_4_5_0',
        'ordinaldragons_5_6_0',
    ],
    ordinaleggs: [
        'ordinaleggs_0_0_0',
        'ordinaleggs_1_1_0',
        'ordinaleggs_2_2_0'
    ],
    toxinstemplar: [
        'toxinstemplar_24_289585_3b41d9830f61cca8711ffdee90d8355e8ece702d59d494d0ad40db0ea295f6eai0',
        'toxinstemplar_27_247066_e7326302b52fed371c0cd74c4f308665348042dd8142a8a9df96936f1a8d95b4i0',
        'toxinstemplar_38_265626_af8d6b524da64684aff41fe125cc16943e9e3371da6265d33c9b204c0c4c6553i0',
    ],
    satoshit: [
        'satoshit_147194_147194_2182d01ef13870830fbf7263f0f1aa32a25670695253f841bb90973591517d35i0',
        'satoshit_147795_147795_8748ed75912f06d43bfc8d35ab78f76f0815418c826486127d7a673c06af670bi0',
        'satoshit_147801_147801_4f8266e0626345a2aa2a1cc17d634f186b86cfb23362b13d5c7f4b4943780c2ci0',
        'satoshit_268019_268019_25eae4a9059341af2392b3dfd416789a127ff5b08b108d5c43d72584ef320e1fi0',
        'satoshit_268027_268027_3fdf7b62746c7d68311cc0816a3120b27e33aaa94053d045daa8c6399b20f29ai0',
        'satoshit_296835_296835_ce5c2708c1d6de0e1e09875a389c28bbf091a3ad3cf32ec1abf91ebc0166976fi0',
        'satoshit_296839_296839_c5359a74ced1f5afae0044e4b7159d4f4f73e17eb07531e79ccdd819bbb19ce7i0',
        'satoshit_296840_296840_09c255bc7387fcb72769307c43e9e66990e8331670f34ffd0dc0391704efdbeei0',
        'satoshit_344570_344570_52ff7f42c5dc95ab19b5356b1b366bfea27844b40b5e2bc87b20346841dc8109i0',
        'satoshit_457205_457205_f69e293094c68f7c0f2021f41e447036b8e5367141be7535006d3ac5d3525ddbi0',
        'satoshit_457722_457722_c27410cc59ff09cef1f2200300ddb026afbfdc980b8139f57205471f56c93976i0',
    ],
    bitcoinchimps: [
        'bitcoinchimps_0_0_0',
        'bitcoinchimps_1_1_0',
        'bitcoinchimps_2_2_0',
    ],
    bapc: [
        'bapc_602578_602578_92ee07dd5cdd6d5e2c99bc4648830e4f0cace1efb3499e5d5b42b03a03a81a0bi0',
        'bapc_605041_605041_935a54c474a6f14f4a0914dd6748f82ef5c6aca76da973ee80bc7783163ef278i0',
        'bapc_605165_605165_af39674c7c3f0ac44d6691c45c0309b8e6618f23e6f787127a16505f36011499i0',
        'bapc_605849_605849_f720c0c8224a5f31ee9a31c5201d728007d21cc5a6231df10046905e307295d0i0',
        'bapc_10810075_10810075_53c9b01e038684cdf912f6f3edba627a6210b4c16725bb2a82602b634cab9745i0',
        'bapc_10810670_10810670_c3b9b65aeeeae08d3337770011d0e199d721eb3757abca9e66c93d3baffab96ci0'
    ],
    pomb: [
        'pomb_14577106_14577106_8403d2c2655644cbd7d4973b55a290d281585eedb9bc97dd5589e9999831f494i0',
        'pomb_14304754_14304754_7133f143c62f0deaa88019122cfbf213dd66fb9609a1d151980f36470e3018abi0',
        'pomb_14493601_14493601_b199f866df0a9ac2967bba7f12bd3e1c580ba7c611d68329b6db7ec5f0d9c344i0',
    ],
    lazysats: [
        'lazysats_0_0_0',
        'lazysats_1_1_0',
        'lazysats_2_2_0'
    ],
    ordinalunis: [
        'ordinalunis_515_515_0',
        'ordinalunis_525_525_0',
        'ordinalunis_582_582_0'
    ],
    ordinalfomojis: [
        'ordinalfomojis_11_18961_f79b7180eb873f69a9de35b7faacccd3a69a3d91237fc75aaf879cad3d0d0021i0',
        'ordinalfomojis_15_18965_b76fb47892d05593a81d2bd7f2137e43632346bc2e282908d4fa11772155f031i0',
        'ordinalfomojis_73_21804_f4eeebfb69f1c8ea81bc0c06ee6347ca885d2072ae0bf711b87512414b09f5c7i0',
        'ordinalfomojis_92_22911_f5c5220f179b15c3ba04bfb43a3c949f7fd4734dfacf0c45fc4a01f876e708f0i0',
    ],
    anons: [
        'anons_483_8718720_be8b5e71d458fda6c52a58bbdff023e141d0f22186e13d8e88a78ac7172dfd40i0',
        'anons_487_8718724_c8161e7c3c339cc0dd3e7a4e8c30988e22f0200f54a08bc836c0ba2328c6ad1fi0',
        'anons_488_8718725_695de305e065a55a2ffee0365f48eb10ca56ff44fee4439992726a169dd63142i0',
        'anons_491_8718728_af138ee7a5286ea296fb0d3e845d567b59086e01a5970be36de321b2671fce61i0',
        'anons_495_8718732_719adbd72cec12cc6523a67a3ecdef215f6ff874538fe369480a873f64996670i0',
    ],
    degenape: [
        'degenape_1_1_0',
        'degenape_2_2_0',
        'degenape_3_3_0',
        'degenape_4_4_0',
    ],
    taprootcows: [
        'taprootcows_1_1_0',
        'taprootcows_2_2_0',
        'taprootcows_3_3_0',
        'taprootcows_4_4_0',
    ],
    sloth: [
        'sloth_1_1_0',
        'sloth_2_2_0',
        'sloth_3_3_0',
    ],
    omc: [
        'omc_1_1_0',
        'omc_2_2_0',
        'omc_3_3_0',
        'omc_4_4_0',
        'omc_5_5_0',
        'omc_6_6_0',
        'omc_7_7_0',
    ],
    ordinalmages: [
        'ordinalmages_1_1_0',
        'ordinalmages_2_2_0',
        'ordinalmages_3_3_0',
    ],
    inscribables: [
        'inscribables_1_1_0',
        'inscribables_2_2_0',
        'inscribables_3_3_0',
        'inscribables_4_4_0',
        'inscribables_5_5_0',
        'inscribables_6_6_0',
        'inscribables_7_7_0',
    ],
    btcwhales: [
        'btcwhales_1_1_0',
        'btcwhales_2_2_0',
        'btcwhales_3_3_0',
        'btcwhales_4_4_0',
        'btcwhales_5_5_0',
    ],
    pioneers: [
        'pioneers_1_1_0',
        'pioneers_2_2_0',
        'pioneers_3_3_0',
        'pioneers_4_4_0',
        'pioneers_5_5_0',
        'pioneers_6_6_0',
    ],
    npc: [
        'npc_3908_9048862_55a208d3f6f677539a7edb075616125c7033059e00d0632303df3b03902a97cfi0',
        'npc_7885_9048969_48df3f4f80ac9fa682c5d27a5578c1fd13a2bea158fb5fed77dbf432615f7f3di0',
        'npc_8607_7987708_d6b2478ca24306d15c452ae00e89511d78e98607fb6f0fdc2494d134bbc4af9ci0',
        'npc_8815_7840722_7049177d3a34b7e5ab4d573b70c3d1b175fa7b77c45f7f99f98ff9faacfea319i0',
        'npc_9281_7885132_68b5c667d86b46c7b64cf7d753fb5ea51ea6f8a35381980f46abe86783da11eai0',
    ],
    aperunners: [
        'aperunners_1913_9057782_2cd9d44b324efd557d2aa6fee73f655558f77bd85642862b668d73f567961af9i0',
        'aperunners_1960_9057813_b31bde90d6f5e6b4a7b291ea30afb774e2c34407907cab817192f70395f54e3ei0',
        'aperunners_2029_9057524_d8bb36bb3045a8a3eb7c3f68e94c23e4514e3f262e53c7999d4192022ef875c0i0',
        'aperunners_2073_9057961_4276c81571461c9f7cf04070ba2c36e4b4b3c9dd785a5872e5ae331b1770aaf8i0'
    ],
    cpc: [
        'cpc_972_972',
        'cpc_815_815',
        'cpc_734_734',
    ],
    bapcgenesis: [
        'bapcgenesis_195_73026906809236378749092060512725513026819369494550064736678558885533278273537',
        'bapcgenesis_469_73026906809236378749092060512725513026819369494550064736678559415497882861569',
        'bapcgenesis_41_73026906809236378749092060512725513026819369494550064736678558711810441084929',
        'bapcgenesis_380_73026906809236378749092060512725513026819369494550064736678559092241464295425',
        'bapcgenesis_375_73026906809236378749092060512725513026819369494550064736678559086743906156545'
    ],
    tribeordinals: [
        'tribeordinals_1031425_1031425_8baf2ba4ab56f3eacfff29cb99c58538930373cdbbed8d14c93a6293f397e0bfi0',
    ],
    tribeodyssey: [
        'tribeodyssey_7441_7441',
        'tribeodyssey_1414_1414',
        'tribeodyssey_11424_11424',
        'tribeodyssey_1165_1165',
    ],
    btcbabbies: [
        'btcbabbies_0_251016_4924378d2255ebe76e43031cea817d705570d5a5f97275c8bf25ccb66846cfa9i0',
        'btcbabbies_1_314262_0aea8808fb831241a9e8283430415e86626b563f18ca1bedd6ba366fbdc75aa4i0',
        'btcbabbies_2_314259_c9f9ad7fa0c0a42e9c5bb2146bb46cfdaca1f3c13e3a00d2bfd92b1be5685689i0',
        'btcbabbies_3_314265_9c6bfbebb007505feb10c4653b0f3bfc18e714a7d13a0fcfefd33f5785b7fdc3i0',
    ],
    skelly: [
        'skelly_1_24684304_85b7d677607a6652dbb70e7cba3e3e35f5845ead1b3c399fa2b6547f254bf2bbi0',
        'skelly_2_24328906_7dcbba3ce4d704787fc78b820b6f154dcba2f2f64b6c76b9aec61cc890e331b6i0',
        'skelly_3_24280562_2ad6cc3124d4847b51d2892b884bd9c91736a09fec4f48726f2bc2b2969b11f6i0',
    ],
    almc: [
        'almc_1_1_0',
        'almc_2_2_0',
        'almc_3_3_0',
        'almc_4_4_0',
        'almc_5_5_0',
        'almc_6_6_0',
        'almc_7_7_0',
    ],
    tinyminotaurs: [
        'tinyminotaurs_1_1_0',
        'tinyminotaurs_2_2_0',
        'tinyminotaurs_3_3_0',
        'tinyminotaurs_4_4_0'
    ],
    bob: [
        'bob_26167975_26167975_745ca70fc6f5224ffcdc78b3487475e7be55a8c05290d2cb62e8e27f89cd752ci0',
        'bob_26167976_26167976_75ba66c3ed2437ddf5186e06f7128d9680f8fad051885cc7ca29e37bdb21012fi0',
        'bob_26167977_26167977_f4a4cfa36d55c56ce2e1d08166c11f01a9d055dd494b8bcaadc9b7a504293934i0',
        'bob_26167985_26167985_02742f30b90da4a6b4e4f3b9097ed03bb110f9382a97048548eada2ac085bc5fi0',
        'bob_26168004_26168004_1d89e25708ffa5a5591ebe4020d49d341e3ca52783d66de99d49d92cf4a259e5i0'
    ],
    psychlops: [
        'psychlops_1_1_0',
        'psychlops_2_2_0',
        'psychlops_3_3_0',
        'psychlops_4_4_0',
        'psychlops_5_5_0'
    ],
    btcbuds: [
        'btcbuds_1_127728_bbd1924449f5e201d8e47c7a04e29799cd88649504f9a90baa028835c7981e14i0'
    ],
    taruguitos: [
        'taruguitos_1097743_1097743_69846620d38642f4ca0d1b8c34ca558e425f9850aeae9cd5745d40b433373388i0',
        'taruguitos_32739451_32739451_0adb2e008298a31ad7f828fc1f212a6ba329c257cd457376edd365a7acd9af76i0',
        'taruguitos_32749193_32749193_69dfb724dfe6392d360d83a15b0c2a9a28028c1117257b4d2090f6198621434ci0',
        'taruguitos_32749207_32749207_8dbb12017d16bcec1073f3cb5c392ef2ea81cefd0c3694142ba3ab485eb9d8aci0',
        'taruguitos_32749212_32749212_2e0b60a83522b3be5ea34de5d44c9a38f30dc8f478e9416c76cf497725d9b1bfi0',
    ],
    clownmigos: [
        'clownmigos_1184_1184',
        'clownmigos_2972_2972',
        'clownmigos_3991_3991'
    ],
    toshiorder: [
        'toshiorder_1011_35075491_c74377651eff556207e4112c2239d2875d6d0f86e482c42538683598d34a31cai0',
        'toshiorder_1254_35077838_6d4a5f65d8e182d478f0e8256d238e89504fa75d75ddbc6d1ed423c9ff6ca82di0',
        'toshiorder_1305_35077081_b49ffbc76cc832ffc010b29643f401c7b29bd2bd8fdd2826684800f60b161c04i0',
        'toshiorder_2679_35075351_9d5f6adf93c7b5ff6e167f51446f8f66f36ca087515d96ac9df16801837d3a8di0',
        'toshiorder_3098_35075477_5d57f0619a07d7ec54e111377d9465110ed5f249ff25e75cdf2deb5e03c7f99fi0',
    ]
};

export const unityConfigs = (gameTitle, type) => {
    let screenType = 'desktop';

    if (type) {
        screenType = 'mobile';
    }

    if (gameTitle !== 'rocketordinals') {
        screenType = 'cross';
    }

    return {
        loaderUrl: `${serverEnvironment}/games/${gameTitle}/${screenType}/Build/build_test_webgl.loader.js`,
        dataUrl: `${serverEnvironment}/games/${gameTitle}/${screenType}/Build/build_test_webgl.data`,
        frameworkUrl: `${serverEnvironment}/games/${gameTitle}/${screenType}/Build/build_test_webgl.framework.js`,
        codeUrl: `${serverEnvironment}/games/${gameTitle}/${screenType}/Build/build_test_webgl.wasm`,
        // webGLContextAttributes: {
        //     preserveDrawingBuffer: true,
        // },
    }
}


// export const unityConfigs = async (gameTitle, type) => {
//     let screenType = 'desktop';

//     if (type) {
//         screenType = 'mobile';
//     }

//     if (gameTitle !== 'rocketordinals') {
//         screenType = 'cross';
//     }

//     const fileNames = [
//         'build_test_webgl.loader.js',
//         'build_test_webgl.data',
//         'build_test_webgl.framework.js',
//         'build_test_webgl.wasm',
//     ];

//     const headers = { 'X-API-KEY': 'godsolovedtheworld' };

//     // Fetch all files with the api key header
//     const fetches = fileNames.map((fileName) =>
//         fetch(`${serverEnvironment}/games/${gameTitle}/${screenType}/Build/${fileName}`, { headers })
//     );

//     const responses = await Promise.all(fetches);

//     // Check if any response was unsuccessful and throw an error
//     responses.forEach((response, index) => {
//         if (!response.ok) throw new Error(`Failed to fetch ${fileNames[index]}`);
//     });

//     // Get blobs from responses
//     const blobs = await Promise.all(responses.map((response) => response.blob()));

//     // Create blob urls from blobs
//     const blobUrls = blobs.map((blob) => {
//         return URL.createObjectURL(blob)
//     });

//     return {
//         loaderUrl: blobUrls[0],
//         dataUrl: blobUrls[1],
//         frameworkUrl: blobUrls[2],
//         codeUrl: blobUrls[3],
//     };
// }

export const screenBreakpoints = {
    xs: 0,
    sm: 600,
    md: 960,
    lg: 1280,
    xl: 1920
}

export const serverEnvironment = env[3] === 'dev' ? 'http://localhost:8080' : 'https://54.159.10.224';
// export const serverEnvironment = env[1] === 'dev' ? 'http://10.0.0.164:8080' : 'https://54.159.10.224';