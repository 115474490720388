import {
  Box,
  Typography,
  Container,
  Grid,
  Card,
  CardContent,
  CardActionArea,
  CardMedia,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import Navbar from 'components/navbar';
import ReactGA from 'react-ga';

const HomePage = () => {
  ReactGA.initialize('G-RF6770D6JZ');
  // Redux State
  const user = useSelector((state) => state.user);
  const ordinalAddress = user && user.ordinalAddress;
  const navigate = useNavigate();

  // Google analytics Page View
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  // Just in case we need the mobile version even a simple one
  // const isNonMobileScreens = useMediaQuery('(min-width:1000px)');
  return (
    <div>
      <Navbar />
      <Container>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4}>
              <Card
                sx={{ minWidth: 275 }}
                style={{
                  height: '450px',
                  borderRadius: '20px',
                  textAlign: 'center',
                }}
              >
                <CardActionArea
                  onClick={() => {
                    ReactGA.event({
                      category: 'routing',
                      action: 'game_page',
                      label: 'GamePage',
                    });
                    navigate(`/games/battlegunz`);
                  }}
                >
                  <CardMedia
                    component="img"
                    image="/assets/games/battlegunz.png"
                    alt="Battle Gunz"
                    height="380"
                  />
                  <CardContent>
                    <Typography
                      gutterBottom
                      variant="h5"
                      component="div"
                      color="primary"
                    >
                      <strong>PLAY NOW</strong>
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Card
                sx={{ minWidth: 275 }}
                style={{
                  height: '450px',
                  borderRadius: '20px',
                  textAlign: 'center',
                }}
              >
                <CardActionArea
                  onClick={() => {
                    ReactGA.event({
                      category: 'routing',
                      action: 'game_page',
                      label: 'GamePage',
                    });
                    navigate(`/games/meteordinals`);
                  }}
                >
                  <CardMedia
                    component="img"
                    image="/assets/games/meteordinals.png"
                    alt="Rocket Ordinals"
                    height="380"
                  />
                  <CardContent>
                    <Typography
                      gutterBottom
                      variant="h5"
                      component="div"
                      color="primary"
                    >
                      <strong>PLAY NOW</strong>
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Card
                sx={{ minWidth: 275 }}
                style={{
                  height: '450px',
                  borderRadius: '20px',
                  textAlign: 'center',
                }}
              >
                <CardActionArea
                  onClick={() => {
                    ReactGA.event({
                      category: 'routing',
                      action: 'game_page',
                      label: 'GamePage',
                    });
                    navigate(`/games/rocketordinals`);
                  }}
                >
                  <CardMedia
                    component="img"
                    image="/assets/rocket_ordinals.png"
                    alt="Rocket Ordinals"
                    height="380"
                  />
                  <CardContent>
                    <Typography
                      gutterBottom
                      variant="h5"
                      component="div"
                      color="primary"
                    >
                      <strong>PLAY NOW</strong>
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
            {[1, 1, 1].map((item, index) => (
              <Grid key={index} item xs={12} sm={6} md={4}>
                <Card
                  sx={{ minWidth: 275 }}
                  style={{
                    backgroundColor: 'black',
                    height: '450px',
                    borderRadius: '20px',
                  }}
                >
                  <CardContent style={{ textAlign: 'center' }}>
                    <img
                      src={`/assets/question_mark_white.png`}
                      alt={item.title}
                      loading="lazy"
                      style={{}}
                    />
                    <Typography
                      sx={{ fontSize: 30 }}
                      color="white"
                      gutterBottom
                    >
                      COMING SOON
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Container>

      <Container style={{ color: 'white' }}>
        <br />
        <Typography component="h4" variant="h4" align="center" gutterBottom>
          <span className="montserrat-bold" style={{}}>
            WORLD'S FIRST BTC ORDINALS PLAYABLE GAME
          </span>
        </Typography>
        <Typography variant="h5" align="center" paragraph>
          <span className="montserrat-bold" style={{}}>
            MORE GAMES AND COLLABS COMING SOON...
          </span>
        </Typography>
      </Container>
    </div>
  );
};

export default HomePage;
