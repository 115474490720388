import { Container, Typography,  Button } from '@mui/material';
import { Unity, useUnityContext } from 'react-unity-webgl';
import { setLogin } from 'state';
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import CharacterSelectDialog from 'components/characterSelectDialog';
import CharacterSelectDialogMobile from 'components/characterSelectDialogMobile';
import LeaderboardDialog from 'components/leaderboardDialog';
import CountdownTimer from 'components/countdownTimer';
import {
  defaultCharacters,
  unityConfigs,
  screenBreakpoints,
} from 'configs/app-config.js';
import { shareOnTwitter } from '../../util/functions';
import ReactGA from 'react-ga';
import {
  serverEnvironment,
  defaultInscriptionIds,
  defaultSelected,
} from 'configs/app-config.js';
import ReactLoading from 'react-loading';
import ReCAPTCHA from 'react-google-recaptcha';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';

const GameContainer = (props) => {
  ReactGA.initialize('G-RF6770D6JZ');
  const { gameName } = useParams();
  const [eventId, setEventId] = useState(null);
  const [discordId, setDiscordId] = useState(null);
  const [eventEnd, setEventEnd] = useState(null);
  const [eventServerName, setEventServerName] = useState(null);
  const [eventStatus, setEventStatus] = useState(null);
  const [eventGameName, setEventGameName] = useState(null);
  const [gameId, setGameId] = useState(null);
  const [eventCollection, setEventCollection] = useState(null);
  // Local State
  const [selectedImage, setSelectedImage] = useState(defaultSelected.character);
  const smallScreen = window.innerWidth < screenBreakpoints.md;
  // Fetch event by event id
  async function fetchEventById(event_id) {
    await fetch(`${serverEnvironment}/discord/select/${event_id}`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ attr: ['status'] }),
    })
      .then(async (data) => {
        const response = await data.json();
        setEventStatus(response.event.status);
      })
      .catch((err) => console.error(err));
  }

  useEffect(() => {
    const userAgent = navigator.userAgent.toLowerCase();
    const isMobileDevice = /mobile/i.test(userAgent);
    setIsMobile(isMobileDevice);
    const eventIdLocal = localStorage.getItem('eventId');
    const discordIdLocal = localStorage.getItem('discordId');
    const eventEndLocal = localStorage.getItem('eventEnd');
    const eventServerNameLocal = localStorage.getItem('eventServerName');
    const eventCollectionLocal = localStorage.getItem('eventCollection');
    const eventGameNameLocal = localStorage.getItem('eventGameName');

    if (eventIdLocal) {
      fetchEventById(eventIdLocal);
      setEventId(eventIdLocal);
      setDiscordId(discordIdLocal);
      setEventEnd(eventEndLocal);
      setEventServerName(eventServerNameLocal);
      setEventCollection(eventCollectionLocal);
      setEventGameName(eventGameNameLocal);
      setGameId(localStorage.getItem('gameId'));
      if (eventCollectionLocal && eventCollectionLocal !== 'all') {
        setSelectedImage(defaultCharacters[eventCollectionLocal][0]);
      }
    }
  }, []);

  useEffect(() => {
    if (eventStatus && eventStatus !== 'active') {
      localStorage.removeItem('eventId');
      localStorage.removeItem('discordId');
      localStorage.removeItem('eventEnd');
      localStorage.removeItem('eventCollection');
      localStorage.removeItem('eventServerName');
      localStorage.removeItem('eventGameName');
      setEventId(null);
      setDiscordId(null);
      setEventEnd(null);
      setEventCollection(null);
      setEventServerName(null);
      setEventStatus(null);
      setEventGameName(null);
    }
  }, [eventStatus]);

  useEffect(() => {
    if (eventId) {
      if (new Date() > new Date(eventEnd)) {
        localStorage.removeItem('eventId');
        localStorage.removeItem('discordId');
        localStorage.removeItem('eventEnd');
        localStorage.removeItem('eventCollection');
        localStorage.removeItem('eventServerName');
        localStorage.removeItem('eventGameName');
        setEventId(null);
        setDiscordId(null);
        setEventEnd(null);
        setEventCollection(null);
        setEventServerName(null);
        setEventGameName(null);
      }
    }
  }, [eventId]);

  // Unity Config
  const unityConfig = unityConfigs(gameName, smallScreen);

  const unityStyle = smallScreen
    ? { height: '75vh', width: '100%' }
    : { width: '60%' };

  const {
    unityProvider,
    addEventListener,
    removeEventListener,
    sendMessage,
    UNSAFE__detachAndUnloadImmediate,
    isLoaded,
    loadingProgression,
    requestFullscreen
  } = useUnityContext(unityConfig);

  // Redux State
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const ordinalAddress = (user && user.ordinalAddress) || '';
  const paymentAddress = (user && user.paymentAddress) || '';
  const ethAddress = (user && user.ethAddress) || '';
  const walletAddress = ordinalAddress || ethAddress;
  const addressType = ethAddress ? 'eth' : '';
  //Change with event
  const [selectedCollection, setSelectedCollection] = useState(
    defaultSelected.collection
  );
  const [savedScoreId, setSavedScoreId] = useState(null);
  const [savedUserName, setSavedUserName] = useState('');
  const [leaderboardOpen, setLeaderboardOpen] = useState(false);
  const [characterSelectDialogOpen, setCharacterSelectDialogOpen] =
    useState(false);

  const [isMobile, setIsMobile] = useState(false);
  const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);

  useEffect(() => {
    const userAgent = navigator.userAgent.toLowerCase();
    const isMobileDevice = /mobile/i.test(userAgent);
    setIsMobile(isMobileDevice);
  }, []);

  const screenType = smallScreen ? 'mobile' : 'desktop';
  useEffect(() => {
    if (isLoaded) {
      sendMessage(
        gameName.includes('rocket') ? 'GameManager' : 'WebGLManager',
        'UpdateBaseUrl',
        `${serverEnvironment}`
      );

      if (eventCollection && eventCollection !== 'all') {
        sendMessage(
          gameName.includes('rocket') ? 'GameManager' : 'WebGLManager',
          'UpdateCharacter',
          `${eventCollection},${defaultCharacters[eventCollection][0]},${screenType}`
        );
      } else {
        sendMessage(
          gameName.includes('rocket') ? 'GameManager' : 'WebGLManager',
          'UpdateCharacter',
          `${defaultSelected.collection.name},${defaultSelected.character},${screenType}`
        );
      }
      if (eventId) {
        sendMessage(
          gameName.includes('rocket') ? 'GameManager' : 'WebGLManager',
          'UpdateEventId',
          `${eventId},${discordId}`
        );
        if (new Date() > new Date(eventEnd)) {
          localStorage.removeItem('eventId');
          localStorage.removeItem('discordId');
          localStorage.removeItem('eventEnd');
          localStorage.removeItem('eventCollection');
          localStorage.removeItem('eventServerName');
          localStorage.removeItem('eventGameName');
          setEventId(null);
          setDiscordId(null);
          setEventEnd(null);
          setEventCollection(null);
          setEventServerName(null);
          setEventGameName(null);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoaded]);

  // Open and close character select dialog
  const handleCharacterSelectDialogOpen = () => {
    setCharacterSelectDialogOpen(true);
    ReactGA.event({
      category: 'trigger_unity',
      action: 'open_character_selection',
      label: 'OpenCharacterSelection',
    });
  };

  const handleCharacterSelectDialogClose = () => {
    setCharacterSelectDialogOpen(false);
  };

  // Open and close leaderboard
  const handleLeaderboardOpen = () => {
    setLeaderboardOpen(true);
    ReactGA.event({
      category: 'trigger_unity',
      action: 'open_leaderboard',
      label: 'OpenLeaderboard',
    });
  };
  const handleLeaderboardClose = () => {
    setLeaderboardOpen(false);
  };

  const canvasRef = useRef(null);

  const captureScreenshot = () => {
    const canvas = canvasRef.current;

    if (canvas) {
      const context = canvas.getContext('2d');
      drawScene(context);

      canvas.toBlob((blob) => {
        saveBlob(blob, `screencapture-${canvas.width}x${canvas.height}.png`);
      });
    }
  };

  const drawScene = (context) => {
    // Your rendering code goes here
  };

  const saveBlob = (blob, fileName) => {
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = fileName;
    link.click();
    window.URL.revokeObjectURL(url);
  };

  // function handleClick() {
  //   captureScreenshot();
  //   // const dataUrl = takeScreenshot('image/jpg', 0.5);
  //   window.open(dataUrl, '_blank');

  //   // const base64Data = dataUrl.replace(/^data:image\/\w+;base64,/, '');
  //   // validateBase64(base64Data);

  //   // sendMessage('GameManager', 'UpdateScreenshot', base64Data);
  // }

  function validateBase64(base64Data) {
    try {
      atob(base64Data);
      console.log('Base64 validation successful.');
    } catch (error) {
      console.error('Invalid Base64 data:', error);
    }
  }
  const handleUpdateScore = useCallback(
    async (user) => {
      if (user) {
        const parsedUser = JSON.parse(user);
        dispatch(setLogin({ user: parsedUser }));
      }
    },
    [ordinalAddress, ethAddress, savedUserName, eventId]
  );

  // Open and close leaderboard
  const handleShareScore = (score, playedCharacterImage) => {
    ReactGA.event({
      category: 'trigger_unity',
      action: 'share_score',
      label: 'ShareScore',
    });
    let playedImage = defaultSelected.character;
    if (playedCharacterImage) {
      playedImage = playedCharacterImage;
    }

    shareOnTwitter(
      {
        gameName,
        collectionName: playedImage.split('_')[0],
        collectionNumber: playedImage.split('_')[1],
        inscriptionNumber: playedImage.split('_')[2],
        inscriptionId: playedImage.split('_')[3],
        score,
      },
      ordinalAddress &&
        !defaultInscriptionIds.includes(playedImage.split('_')[3])
        ? 'my'
        : 'default'
    );
  };

  function handleClickEnterFullscreen() {
    requestFullscreen(true);
  }

  const handleSelectImage = (imageName, type) => {
    const collectionName =
      imageName.split('_')[0] === 'doge'
        ? 'dogepunks'
        : imageName.split('_')[0];
    setSelectedImage(imageName);
    if (type !== 'unlocked') {
      window.open(
        // `https://ordinalswallet.com/inscription/${imageName.split('_')[3]}`,
        `https://magiceden.io/ordinals/item-details/${imageName.split('_')[3]}`,
        '_blank'
      );
      ReactGA.event({
        category: 'button_click',
        action: 'select_locked',
        label: 'SelectLocked',
      });
    } else {
      sendMessage(
        gameName.includes('rocket') ? 'GameManager' : 'WebGLManager',
        'UpdateCharacter',
        `${collectionName},${imageName},${screenType}`
      );
      ReactGA.event({
        category: 'button_click',
        action: 'select_unlocked',
        label: 'SelectUnlocked',
      });
    }
  };

  const handleSelectCollection = (collection) => {
    setSelectedCollection(collection);
  };

  useEffect(() => {
    if (isLoaded) {
      sendMessage(
        gameName.includes('rocket') ? 'GameManager' : 'WebGLManager',
        'UpdateText',
        ordinalAddress ? ordinalAddress : ethAddress ? `${ethAddress}` : ''
      );
      if (!ordinalAddress) {
        // Change for event
        if (eventCollection && eventCollection !== 'all') {
          sendMessage(
            gameName.includes('rocket') ? 'GameManager' : 'WebGLManager',
            'UpdateCharacter',
            `${eventCollection},${defaultCharacters[eventCollection][0]},${screenType}`
          );
        } else {
          sendMessage(
            gameName.includes('rocket') ? 'GameManager' : 'WebGLManager',
            'UpdateCharacter',
            `${defaultSelected.collection.name},${defaultSelected.character},${screenType}`
          );
        }
      }
    }
  }, [isLoaded, ordinalAddress, sendMessage]);

  // From UNITY
  useEffect(() => {
    addEventListener('OpenCharacterDialog', handleCharacterSelectDialogOpen);
    addEventListener('OpenLeaderboard', handleLeaderboardOpen);
    addEventListener('ShareScore', handleShareScore);
    addEventListener('UpdateScore', handleUpdateScore);
    // addEventListener('UpdateName', handleUpdateName);
    return () => {
      removeEventListener(
        'OpenCharacterDialog',
        handleCharacterSelectDialogOpen
      );
      removeEventListener('OpenLeaderboard', handleLeaderboardOpen);
      removeEventListener('ShareScore', handleShareScore);
      removeEventListener('UpdateScore', handleUpdateScore);
      // removeEventListener('UpdateName', handleUpdateName);
    };
  }, [
    addEventListener,
    removeEventListener,
    handleCharacterSelectDialogOpen,
    handleLeaderboardOpen,
    handleUpdateScore,
    handleShareScore,
    // handleUpdateName,
  ]);

  useEffect(() => {
    return () => {
      try {
        UNSAFE__detachAndUnloadImmediate();
      } catch (reason) {
        console.log('==========================================', reason);
      }
    };
  }, [UNSAFE__detachAndUnloadImmediate]);

  // Cpatcha
  const verifyCaptcha = async (token) => {
    if (!token) {
      return;
    }
    // Send the token to your server for verification
    const response = await axios.post(
      `${serverEnvironment}/auth/verify-captcha`,
      { token }
    );

    if (response.data.success) {
      setIsCaptchaVerified(true);
    }
  };

  // Just in case we need the mobile version even a simple one
  // const isNonMobileScreens = useMediaQuery('(min-width:1000px)');
  return (
    <div>
      {eventId && gameName === eventGameName && (
        <CountdownTimer
          serverName={eventServerName}
          endTime={eventEnd}
          isMobile={isMobile}
        />
      )}
      <CharacterSelectDialogMobile
          open={characterSelectDialogOpen}
          projectName="DogePunks"
          eventCollection={eventCollection}
          onClose={handleCharacterSelectDialogClose}
          onSelectImage={handleSelectImage}
          onSelectCollection={handleSelectCollection}
          selected={selectedImage}
        />
      <LeaderboardDialog
        open={leaderboardOpen}
        onClose={handleLeaderboardClose}
        style={{ width: '1000px' }}
        selected={selectedImage}
        gameName={gameName}
      />
      {!isMobile ? (
        <Container style={{ color: 'white' }}>
          {!eventId && <br />}
          <Typography
            component="h5"
            variant="h5"
            align="center"
            gutterBottom
            sx={{
              display: eventId ? 'none' : '',
            }}
          >
            <span className="montserrat-bold" style={{}}>
              CONNECT WALLET AND PLAY TO EARN $ORPL
              {/* MUST CONNECT WALLET TO{' '}
              <span
                style={{
                  backgroundColor: 'blue',
                  border: 'solid white'
                }}
              >
                WIN Pixelated OMB Airdrop
              </span> */}
            </span>
          </Typography>
        </Container>
      ) : (
        <Container
          style={{ color: 'white' }}
          sx={{
            display: eventId ? 'none' : '',
          }}
        >
          {!eventId && <br />}
          <Typography align="center" gutterBottom>
            <span className="montserrat-bold" style={{}}>
              CONNECT WALLET AND PLAY TO EARN $ORPL
              {/* MUST CONNECT WALLET TO{' '}
              <span
                style={{
                  backgroundColor: 'red',
                }}
              >
                WIN Pixelated OMB Airdrop
              </span> */}
            </span>
          </Typography>
        </Container>
      )}
      {/* {serverEnvironment.includes('local') && <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: smallScreen ? '5px' : '20px',
        }}
      >
        <Unity unityProvider={unityProvider} style={unityStyle} />
        
      </div> } */}
      {(!isLoaded || !isCaptchaVerified) && (
        <React.Fragment>
          <ReactLoading
            type="spokes"
            color="#808080"
            style={{
              marginTop: 100,
              display: 'flex',
              justifyContent: 'center',
              height: 100,
            }}
          />
          <br />
          <Typography variant="h5" align="center" paragraph>
            {!isCaptchaVerified ? (
              <span className="montserrat-bold">
                Verifying captcha, please wait...
              </span>
            ) : (
              <span className="montserrat-bold">
                <p>Loading {Math.floor(loadingProgression * 100)}%...</p>
                Loading Unity Module. It might take several minutes for mobile
                environment.
              </span>
            )}
          </Typography>
        </React.Fragment>
      )}
      {/* <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: smallScreen ? '5px' : '20px',
        }}
      >
        <Unity unityProvider={unityProvider} style={unityStyle} />
        
      </div> */}
      {isCaptchaVerified ? (
        <React.Fragment>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: smallScreen ? '10px' : '50px',
          }}
        >
          <Unity unityProvider={unityProvider} style={unityStyle} />
        </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: smallScreen ? '5px' : '10px',
            }}>
            <Button
              variant="contained"
              onClick={handleClickEnterFullscreen}
              size="large"
              style={{
                fontSize: '30px'
              }}
            >
              Enter Fullscreen
            </Button>
          </div>
        </React.Fragment>
      ) : (
        <ReCAPTCHA
          sitekey="6LfSfc0lAAAAAMRmBYZk4AyaJH4YbRjZFoAc2-SR"
          onChange={verifyCaptcha}
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '10px',
          }}
        />
      )}
    </div>
  );
};

export default GameContainer;
