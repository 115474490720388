import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Divider,
  DialogContentText,
  DialogActions,
  Button,
} from '@mui/material';
import { FaDiscord } from 'react-icons/fa';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { serverEnvironment } from 'configs/app-config.js';
import { useNavigate } from 'react-router-dom';
import { logoutDiscord, setDiscord } from 'state';

export default function DiscordSigninDialog(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [openDialog, setOpenDialog] = useState(false);
  const user = useSelector((state) => state.user);
  const discord = useSelector((state) => state.discord);
  const userAgent = navigator.userAgent.toLowerCase();
  const isMobileDevice = /mobile/i.test(userAgent);
  const [inputValue, setInputValue] = useState('');
  const [error, setError] = useState({
    error: false,
    message: '',
  });

  const handleDiscordSignin = () => {
    const event = props.event;
    localStorage.setItem('discordFromEventId', event._id);
    let redirect_uri = encodeURIComponent(
      'https://ordinalsplay.io/discord_login'
    );

    if (serverEnvironment === 'http://localhost:8080') {
      redirect_uri = encodeURIComponent('http://localhost:3000/discord_login');
    }

    // Take user to discord authorization page which THEN takes to DiscordRedirectPage
    window.location.href =
      // 'https://discord.com/api/oauth2/authorize?client_id=1107287420673720440&redirect_uri=http%3A%2F%2Flocalhost%3A8080%2Fauth%2Fdiscord_login&response_type=code&scope=identify%20guilds%20guilds.members.read';
      `https://discord.com/api/oauth2/authorize?client_id=1107287420673720440&redirect_uri=${redirect_uri}&response_type=code&scope=identify`;
  };

  // After successfully loggin in with Discord
  const handleDiscordContinue = () => {
    const event = props.event;
    let present = false;

    if (event.open_registration) {
      present = true;
      localStorage.setItem('eventId', event._id);
      localStorage.setItem('eventServerName', event.serverName);
      localStorage.setItem('discordId', discord.discordId);
      localStorage.setItem('eventEnd', event.endDate);
      localStorage.setItem('eventCollection', event.collectionName);
      localStorage.setItem('eventGameName', event.game);
    }

    for (const participant of event.participants) {
      if (participant.id === discord.discordId) {
        present = true;
        localStorage.setItem('eventId', event._id);
        localStorage.setItem('eventServerName', event.serverName);
        localStorage.setItem('discordId', participant.id);
        localStorage.setItem('eventEnd', event.endDate);
        localStorage.setItem('eventCollection', event.collectionName);
        localStorage.setItem('eventGameName', event.game);
        break;
      }
    }

    if (!present) {
      setError({
        error: true,
        message: 'Oops! You are not registered in this event!',
      });
    } else {
      navigate(`/games/${event.game}`);
    }
  };

  // Logout discord id
  const handleDiscordSignout = () => {
    localStorage.removeItem('discordFromEventId');
    dispatch(logoutDiscord());
  };

  // Manual input of discord handle
  const handleChange = (evt) => {
    setInputValue(evt.target.value);
  };

  // Submit Manual Input
  const handleSubmit = () => {
    const event = props.event;
    // Do something with the input value
    let present = false;
    let inputId = inputValue.trim().toLowerCase();

    for (const participant of event.participants) {
      const handle = `${participant.username}#${participant.discriminator}`;
      if (handle.trim().toLowerCase() === inputId) {
        present = true;
        localStorage.setItem('eventId', event._id);
        localStorage.setItem('eventServerName', event.serverName);
        localStorage.setItem('discordId', participant.id);
        localStorage.setItem('eventEnd', event.endDate);
        localStorage.setItem('eventCollection', event.collectionName);
        localStorage.setItem('eventGameName', event.game);

        dispatch(
          setDiscord({
            discord: {
              discordId: participant.id,
              discordName: participant.username,
              discordDiscriminator: participant.discriminator,
            },
          })
        );
        break;
      }
    }

    if (!present) {
      setError({
        error: true,
        message:
          'The user is not registered in the event. Make sure to click the emoji on the Discord Event Message',
      });
    } else {
      navigate(`/games/${event.game}`);
    }
  };

  useEffect(() => {
    setOpenDialog(props.open);
  }, [props.open, props.selected]);

  return discord && discord.discordId ? (
    <Dialog open={openDialog} disableEscapeKeyDown>
      <DialogTitle style={{ maxWidth: 1000 }}>Verify Your Discord</DialogTitle>
      <Divider />
      <DialogContent
        style={{
          textAlign: 'center',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Button
            onClick={handleDiscordContinue}
            variant="contained"
            style={{
              fontSize: isMobileDevice ? '13px' : '24px',
              backgroundColor: '#7289DA',
            }}
            sx={{
              mb: 2,
            }}
          >
            <FaDiscord
              style={{
                fontSize: '24px',
                color: 'white',
                marginRight: 10,
              }}
            />
            Continue as {discord.discordName}#{discord.discordDiscriminator}
          </Button>
        </div>
        <DialogContentText
          style={{
            fontSize: isMobileDevice ? '11px' : '16px',
          }}
        >
          Signed in as {discord.discordName}#{discord.discordDiscriminator}.{' '}
          <span
            onClick={handleDiscordSignout}
            style={{
              color: '#0000FF',
              cursor: 'pointer',
            }}
          >
            Not you?
          </span>
        </DialogContentText>
        <span style={{ color: 'red' }}>{error.message}</span>
      </DialogContent>

      <DialogActions>
        <Button onClick={() => navigate('/')} variant="outlined">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  ) : (
    <Dialog open={openDialog} disableEscapeKeyDown>
      <DialogTitle style={{ maxWidth: 1000 }}>Verify Your Discord</DialogTitle>
      <Divider />
      <DialogContent>
        {/* <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Button
            onClick={handleDiscordSignin}
            variant="contained"
            style={{
              fontSize: '24px',
              backgroundColor: '#7289DA',
            }}
            sx={{
              mb: 2,
            }}
          >
            <FaDiscord
              style={{
                fontSize: '24px',
                color: 'white',
                marginRight: 10,
              }}
            />
            Connect to Discord
          </Button>
        </div>
        <Divider>OR</Divider> */}
        <DialogContentText>
          Input your discord handle to verify (Ex: john#1234)
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          type="string"
          fullWidth
          placeholder="ex: john#1234"
          variant="standard"
          onChange={handleChange}
          error={error.error}
          helperText={error.message}
        />
      </DialogContent>

      <DialogActions>
        <Button onClick={() => navigate('/')} variant="outlined" color="error">
          Close
        </Button>
        <Button onClick={handleSubmit} variant="contained">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}
