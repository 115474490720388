export const collectionLinks = {
    "dogepunks": {
        "label": "DogePunks",
        "slug": "dogepunks",
        "full": true,
        "twitter": "https://twitter.com/DogePunksBTC",
        "me": "https://magiceden.io/ordinals/marketplace/dogepunks",
        "ow": "https://ordinalswallet.com/collection/dogepunks",
        "image": "https://img-cdn.magiceden.dev/rs:fill:400:400:0:0/plain/https://bafkreie3buh3yb4ok3q52vsr5nagodl3uvmacwlqvtd7hr5nguatwes5jq.ipfs.nftstorage.link/"
    },
    "doge": {
        "label": "DogePunks",
        "slug": "dogepunks",
        "full": true,
        "twitter": "https://twitter.com/DogePunksBTC",
        "me": "https://magiceden.io/ordinals/marketplace/dogepunks",
        "ow": "https://ordinalswallet.com/collection/dogepunks",
        "image": "https://img-cdn.magiceden.dev/rs:fill:400:400:0:0/plain/https://bafkreie3buh3yb4ok3q52vsr5nagodl3uvmacwlqvtd7hr5nguatwes5jq.ipfs.nftstorage.link/"
    },
    "ordinalrobots": {
        "label": "Ordinal Robots",
        "slug": "ordinalrobots",
        "full": true,
        "twitter": "https://twitter.com/OrdinalRobots",
        "me": "https://magiceden.io/ordinals/marketplace/ordinalrobots",
        "ow": "https://ordinalswallet.com/collection/ordinalrobots",
        "image": "https://cdn.ordinalswallet.com/inscription/preview/82ebb0eb60b9e19c7277fcb1ab585f2f048db3551aa8d9be80298d836d90d0e4i0"
    },
    "bitcoinpunks": {
        "label": "Bitcoin Punks",
        "slug": "bitcoin-punks",
        "full": true,
        "twitter": "https://twitter.com/Bitcoin_Punks_",
        "me": "https://magiceden.io/ordinals/marketplace/bitcoin-punks",
        "ow": "https://ordinalswallet.com/collection/bitcoinpunks",
        "image": "https://cdn.ordinalswallet.com/inscription/preview/2d19554bb51891504424409677871f529a1decfa05748f1d6f64a76a36ebcb93i0"
    },
    "bitcoinbandits": {
        "label": "Bitcoin Bandits",
        "slug": "bitcoin-bandits",
        "full": false,
        "twitter": "https://twitter.com/banditsbtc",
        "me": "https://magiceden.io/ordinals/marketplace/bitcoin-bandits",
        "ow": "https://ordinalswallet.com/collection/bitcoin-bandits",
        "image": "https://cdn.ordinalswallet.com/inscription/preview/dbce2723b53c1d83f1928ae711bdecc61026a01b44eb2dac7434d46da07e2400i0"
    },
    "bitcoinbulls": {
        "label": "Bitcoin Bulls",
        "slug": "bitcoinbulls",
        "full": true,
        "twitter": "https://twitter.com/BitcoinBullsNFT",
        "me": "https://magiceden.io/ordinals/marketplace/bitcoinbulls",
        "ow": "https://ordinalswallet.com/collection/bitcoin-bulls",
        "image": "https://img-cdn.magiceden.dev/rs:fill:400:400:0:0/plain/https://bafkreid5xhnfia36h4v7kwinuftqfw5jeikxqcnbinwbnguio4zp4cfl3i.ipfs.nftstorage.link/"
    },
    "ordibots": {
        "label": "Ordibots",
        "slug": "ordibots",
        "full": false,
        "twitter": "https://twitter.com/OrdiBotsClub",
        "me": "https://magiceden.io/ordinals/marketplace/ordibots",
        "ow": "https://ordinalswallet.com/collection/ordibots",
        "image": "https://cdn.discordapp.com/attachments/941724718271180810/1110582237512077323/image.png"
    },
    "ordiblocks": {
        "label": "Ordiblocks",
        "slug": "ordiblocks",
        "full": false,
        "twitter": "https://twitter.com/OrdiBlocks721",
        "me": "https://magiceden.io/ordinals/marketplace/ordiblocks",
        "ow": "https://ordinalswallet.com/collection/ordiblocks",
        "image": "https://img-cdn.magiceden.dev/rs:fill:400:400:0:0/plain/https://bafkreiaarmbtp73wjmrjroi47teshp6l2eiyw7gtgie2i2yljnbm3q5tfq.ipfs.nftstorage.link/"
    },
    "ordinaldragons": {
        "label": "Ordinal Dragons",
        "slug": "ordinaldragons",
        "full": false,
        "twitter": "https://twitter.com/DragonOrdinals",
        "me": "https://magiceden.io/ordinals/marketplace/ordinaldragons",
        "ow": "https://ordinalswallet.com/collection/ordinaldragons",
        "image": "https://img-cdn.magiceden.dev/rs:fill:400:400:0:0/plain/https://bafybeihxixsqsfxasbccecnj357cgtcu7cwsuxhmczgovivzzx32ks2wv4.ipfs.nftstorage.link/"
    },
    "ordinaleggs": {
        "label": "Ordinals Egg",
        "slug": "ordinals-egg",
        "full": false,
        "twitter": "https://twitter.com/OrdiBeasts",
        "me": "https://magiceden.io/ordinals/marketplace/ordinals-egg",
        "ow": "https://ordinalswallet.com/collection/ordinalsegg",
        "image": "https://img-cdn.magiceden.dev/rs:fill:400:400:0:0/plain/https://bafkreibt5fydqaqo5np3lsu5ynh5niv72gp44l4svuyyfkxaqiomdsdwui.ipfs.nftstorage.link/"
    },
    "toxinstemplar": {
        "label": "Toxins Templar",
        "slug": "toxins-templar",
        "full": false,
        "twitter": "https://twitter.com/DegenSoul",
        "os": "https://ordswap.io/collections/toxins-templar",
        "image": "https://preview.ordswap.io/8e96ece7518e2a3f2704c59a2d40f9182486cc1e875968078194e19ca309110bi0"
    },
    "satoshit": {
        "label": "Satoshit",
        "slug": "satoshits",
        "twitter": "https://twitter.com/Satoshits_BTC",
        "full": false,
        "me": "https://magiceden.io/ordinals/marketplace/satoshits",
        "image": "https://img-cdn.magiceden.dev/rs:fill:400:400:0:0/plain/https://v5.airtableusercontent.com/v1/17/17/1685894400000/1cSSS4CF1yvkq_D-EoUedg/tGHEbPTOB4Q1rAg3W1WF51u8XYNdsBo0k4GFPtH8bNgAHCRf5Pxi3A35Qkpz6nxSKhSqn8f0JeT-JNE08mo6Uw6Qd_U76KyeafLdCzN1ltg/WxuDbfpR5ptjd1MKXU8wqeCRyUWorHjKtYtJCpXqpyw"
    },
    "bitcoinchimps": {
        "label": "Bitcoin Chimps",
        "slug": "btc-chimps",
        "twitter": "https://twitter.com/BitcoinChimps",
        "full": false,
        "me": "https://magiceden.io/ordinals/marketplace/btc-chimps",
        "ow": "https://ordinalswallet.com/collection/btc-chimps",
        "image": "https://img-cdn.magiceden.dev/rs:fill:400:400:0:0/plain/https://bafkreifzzvsf6gp3bwec3cpr4xkv25c6rouuhiacbl52xw2xuruyqoggyi.ipfs.nftstorage.link/"
    },
    "bapc": {
        "label": "BAPC",
        "slug": "bapco",
        "twitter": "https://twitter.com/BoredApePixelCl",
        "full": false,
        "me": "https://magiceden.io/ordinals/marketplace/bapco",
        "image": "https://img-cdn.magiceden.dev/rs:fill:400:400:0:0/plain/https://bafkreibrxmpnearsd4vwusxqxxwg3uhzljvxojirrif2hg7sb3jdcupmcm.ipfs.nftstorage.link/"
    },
    "pomb": {
        "label": "Pixelated OMB",
        "slug": "block9_pomb",
        "full": false,
        "me": "https://magiceden.io/ordinals/marketplace/block9_pomb",
        "twitter": "https://twitter.com/PixelatedOmb",
        "image": "https://img-cdn.magiceden.dev/rs:fill:400:400:0:0/plain/https://creator-hub-prod.s3.us-east-2.amazonaws.com/ord-block9_pomb_pfp_1688704272297.png"
    },
    "lazysats": {
        "label": "Lazy Sats",
        "slug": "lazysats",
        "full": false,
        "twitter": "https://twitter.com/LazySats",
        "image": "https://img-cdn.magiceden.dev/rs:fill:400:400:0:0/plain/https://creator-hub-prod.s3.us-east-2.amazonaws.com/ord-pixelated-omb_pfp_1688396289748.png"
    },
    "ordinalunis": {
        "label": "Ordinal Unis",
        "full": false,
        "slug": "ordinal-unis",
        "twitter": "https://twitter.com/OrdinalUnis",
        "me": "https://magiceden.io/ordinals/marketplace/ordinal-unis",
        "image": "https://img-cdn.magiceden.dev/rs:fill:400:400:0:0/plain/https://bafkreihxx6rg7ks4vjbrcosdmngy6ixnhxrxwmh2n22iz75xg7melbjrcm.ipfs.nftstorage.link/"
    },
    "ordinalfomojis": {
        "label": "Ordinal Fomojis",
        "full": true,
        "slug": "ordinal-fomojis",
        "twitter": "https://twitter.com/OrdinalFomojis",
        "me": "https://magiceden.io/ordinals/marketplace/ordinal-fomojis",
        "ow": "https://ordinalswallet.com/collection/ordinal-fomojis",
        "image": "https://img-cdn.magiceden.dev/rs:fill:400:400:0:0/plain/https://bafkreiemvwqh3cpg2wwdcvuq5crv3harzn7gpzfbke6v7vtntjgmwi44u4.ipfs.nftstorage.link/"
    },
    "anons": {
        "label": "Ordinal Anons",
        "slug": "anons",
        "full": false,
        "twitter": "https://twitter.com/OrdinalAnons",
        "me": "https://magiceden.io/ordinals/marketplace/anons",
        "ow": "https://ordinalswallet.com/collection/anons",
        "image": "https://img-cdn.magiceden.dev/rs:fill:400:400:0:0/plain/https://bafkreifzctpe23fjarlzcwsfptl3cqlxt6d2bydkmkxnshl43bfviybbfm.ipfs.nftstorage.link/"
    },
    "degenape": {
        "label": "Degen Ape Squad",
        "full": false,
        "image": "https://degenapesquad.com/wp-content/uploads/2023/06/6-modified-min-1024x1024.png",
        "twitter": "https://twitter.com/Degen_Ape_Squad"
    },
    "taprootcows": {
        "label": "Taproot Cows",
        "slug": "taproot-cows",
        "full": false,
        "image": "https://cdn.ordinalswallet.com/inscription/content/79e2910b0e9f19d9d41b5400609f1dee08e7b817941260bb5718a4f8a84b9300i0",
        "me": "https://magiceden.io/ordinals/marketplace/taproot-cows",
        "twitter": "https://twitter.com/taprootcowsfam"
    },
    "sloth": {
        "label": "SlothsHQ",
        "full": false,
        "image": "https://pbs.twimg.com/media/F0N7jJTWAAA1ApE?format=jpg&name=small",
        "twitter": "https://twitter.com/slothshq"
    },
    "omc": {
        "label": "Ordinal Monkey Club",
        "full": false,
        "slug": "omc",
        "image": "https://pbs.twimg.com/profile_images/1666133107584512012/Ugcbwahu_400x400.jpg",
        "me": "https://magiceden.io/ordinals/marketplace/omc",
        "twitter": "https://twitter.com/Ordinal_Monkey"
    },
    "ordinalmages": {
        "label": "Ordinal Mages",
        "slug": "ordinal_mages",
        "full": false,
        "image": "https://img-cdn.magiceden.dev/rs:fill:400:400:0:0/plain/https://bafkreibagyoss7fxr3wcd6bspmf7f6cislldg3qa3dhkidg5r2e7dh7vqu.ipfs.nftstorage.link/",
        "me": "https://magiceden.io/ordinals/marketplace/ordinal_mages",
        "twitter": "https://twitter.com/Ordinal_Mages"
    },
    "inscribables": {
        "label": "Inscribables",
        "full": false,
        "slug": "scribe_squad",
        "image": "https://img-cdn.magiceden.dev/rs:fill:400:400:0:0/plain/https://creator-hub-prod.s3.us-east-2.amazonaws.com/ord-scribe_squad_pfp_1689115112117.jpeg",
        "me": "https://magiceden.io/ordinals/marketplace/scribe_squad",
        "twitter": "https://twitter.com/ord_hashes"
    },
    "btcwhales": {
        "label": "Bitcoin Whales",
        "full": false,
        "slug": "bitcoinwhales",
        "image": "https://img-cdn.magiceden.dev/rs:fill:400:400:0:0/plain/https://bafkreidhrznlzsdei7k2ar3u44k2ehmzyt3ou5ohzn754fdf7zzd62tvji.ipfs.nftstorage.link/",
        "me": "https://magiceden.io/ordinals/marketplace/bitcoinwhales",
        "twitter": "https://twitter.com/Bitcoin_Whales_"
    },
    "pioneers": {
        "label": "Pioneers",
        "full": false,
        "slug": "pioneers",
        "image": "https://pbs.twimg.com/media/F1AccreWAAEBGfC?format=jpg&name=large",
        "me": "https://magiceden.io/ordinals/marketplace/bitcoin-sneakers",
        "twitter": "https://twitter.com/BitcoinSneakers"
    },
    "npc": {
        "label": "NPC Ordinals",
        "slug": "npc",
        "full": true,
        "image": "https://img-cdn.magiceden.dev/rs:fill:400:400:0:0/plain/https://bafkreie5k3ltb5cgziskpg6mgwgyjjkvb2p2t3hyw4ypcbuss272qkppu4.ipfs.nftstorage.link/",
        "me": "https://magiceden.io/ordinals/marketplace/npc",
        "twitter": "https://twitter.com/NPCOrdinals"
    },
    "aperunners": {
        "label": "Ape Runners",
        "slug": "ape-runners",
        "full": false,
        "image": "https://img-cdn.magiceden.dev/rs:fill:400:400:0:0/plain/https://bafkreicjwszevsyskhe57oszv2g6tqdusoyinxot7on6vmp37dsanaieeu.ipfs.nftstorage.link/",
        "me": "https://magiceden.io/ordinals/marketplace/ape-runners",
        "twitter": "https://twitter.com/ApeRunnersBTC"
    },
    "cpc": {
        "label": "Cool Punk Cats",
        "slug": "cool-punk-cats",
        "full": false,
        "image": "https://i.seadn.io/gcs/files/5b423f444a972f1d645e22009de1b438.png?auto=format&dpr=1&w=384",
        "opensea": "https://opensea.io/collection/cool-punk-cats",
        "twitter": "https://twitter.com/CoolPunkCats"
    },
    "bapcgenesis": {
        "label": "Bored Ape Pixel Club Genesis",
        "slug": "bored-ape-pixel",
        "full": true,
        "image": "https://i.seadn.io/gae/yIWdaMyEyI8hFdW1ZIgs90FlMRMdUUo6E-WCYXooIzR-HLXA3Cyclx1SB_MTN8AvAhZI6lVfPsBVjxY0yifWCEBd25fa8F-wwm8IMw?auto=format&dpr=1&w=1000",
        "opensea": "https://opensea.io/collection/bored-ape-pixel",
        "twitter": "https://twitter.com/BoredApePixelCl"
    },
    "tribeordinals": {
        "label": "Tribe Ordinals",
        "slug": "tribe-ordinals",
        "full": true,
        "image": "https://img-cdn.magiceden.dev/rs:fill:400:400:0:0/plain/https://bafkreigtu4wbeknp3dcn2dparjw2x6tgfibwdri2ajgjyvasoxg2tcmqku.ipfs.nftstorage.link/",
        "me": "https://magiceden.io/ordinals/marketplace/tribeordinals",
        "twitter": "https://twitter.com/tribeodyssey"
    },
    "tribeodyssey": {
        "label": "Tribe Odyssey",
        "slug": "tribe-odyssey",
        "full": true,
        "image": "https://img-cdn.magiceden.dev/rs:fill:400:400:0:0/plain/https://bafkreigtu4wbeknp3dcn2dparjw2x6tgfibwdri2ajgjyvasoxg2tcmqku.ipfs.nftstorage.link/",
        "opensea": "https://opensea.io/collection/tribe-odyssey",
        "twitter": "https://twitter.com/tribeodyssey"
    },
    "btcbabbies": {
        "label": "Bitcoin Babbies",
        "slug": "btcbabbies",
        "full": true,
        "image": "https://cdn.ordinalswallet.com/inscription/preview/ffeff5cabe38833383f96b736aa1a5e74b9ad5927a8d5a041a57eab9336fe91bi0",
        "me": "https://magiceden.io/ordinals/marketplace/btcbabbies",
        "twitter": "https://twitter.com/BTCbabbies"
    },
    "skelly": {
        "label": "Skelly Uni",
        "slug": "skelly",
        "full": false,
        "image": "https://content.sado.space/ipfs/bafkreigdwjs2qkh6w75hf3454j3xrbi5he3lt56g6v4jywwxo2my7g6iry",
        "ordzaar": "https://ordzaar.com/marketplace/collections/skellyuni",
        "twitter": "https://twitter.com/SkellyUni"
    },
    "almc": {
        "label": "Awkward Look Monkey Club",
        "slug": "almc",
        "full": false,
        "image": "https://pbs.twimg.com/profile_images/1689748958782865408/Fc1ndGtI_400x400.jpg",
        "twitter": "https://twitter.com/ALMCOrdinals"
    },
    "tinyminotaurs": {
        "label": "Tiny Minotaurs",
        "slug": "tinyminotaurs",
        "full": false,
        "image": "https://pbs.twimg.com/profile_images/1635221011523317760/iTS1QECN_400x400.jpg",
        "twitter": "https://twitter.com/TinyMinotaurs"
    },
    "bob": {
        "label": "Bob on Bitcoin",
        "slug": "bob",
        "me": "https://magiceden.io/ordinals/marketplace/bob_on_bitcoin",
        "full": false,
        "image": "https://img-cdn.magiceden.dev/rs:fill:400:400:0:0/plain/https://creator-hub-prod.s3.us-east-2.amazonaws.com/ord-bob_on_bitcoin_pfp_1693848021950.png",
        "twitter": "https://twitter.com/OrdinalBob"
    },
    "psychlops": {
        "label": "BTC Psychlops",
        "slug": "psychlops",
        "full": false,
        "image": "https://pbs.twimg.com/profile_images/1475965370636902402/mylQrUVw_400x400.jpg",
        "twitter": "https://twitter.com/psychlopsnft"
    },
    "btcbuds": {
        "label": "Bitcoin Buds",
        "slug": "bitcoin-buds",
        "me": "https://magiceden.io/ordinals/marketplace/bitcoin-buds",
        "full": false,
        "image": "https://pbs.twimg.com/profile_images/1642962783401807874/LnPFTuwJ_400x400.jpg",
        "twitter": "https://twitter.com/BestBudsBTC"
    },
    "taruguitos": {
        "label": "The Taruguitos",
        "slug": "the-taruguitos",
        "me": "https://magiceden.io/ordinals/marketplace/the-taruguitos",
        "full": false,
        "image": "https://img-cdn.magiceden.dev/rs:fill:400:400:0:0/plain/https://bafkreifdah2xxit7h4q756awx7xk6osxxgyeunc544hwvuqjdqmfuudw5e.ipfs.nftstorage.link/",
        "twitter": "https://twitter.com/the_taruguitos"
    },
    "clownmigos": {
        "label": "clownmigos",
        "slug": "clownmigos",
        "opensea": "https://opensea.io/collection/clownmigos",
        "full": false,
        "image": "https://i.seadn.io/gcs/files/1d4f1962b7f6af16e29aa3205d4ba238.png?auto=format&dpr=1&w=256",
        "twitter": "https://twitter.com/Clownmigos"
    },
    "toshiorder": {
        "label": "toshiorder",
        "slug": "thetoshiorder",
        "me": "https://magiceden.io/ordinals/marketplace/thetoshiorder",
        "full": false,
        "image": "https://img-cdn.magiceden.dev/rs:fill:400:0:0/plain/https://creator-hub-prod.s3.us-east-2.amazonaws.com/ord-thetoshiorder_pfp_1696541197692.png",
        "twitter": "https://twitter.com/sat_toshis"
    }
};