import {
  Container,
  Typography,
  SpeedDial,
  SpeedDialIcon,
  SpeedDialAction,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Link,
  Button,
  DialogContentText,
} from '@mui/material';
import { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { serverEnvironment } from 'configs/app-config.js';
import Navbar from 'components/navbar';

const ErrorPage = () => {
  const { gameId, eventId } = useParams();
  const navigate = useNavigate();

  // async function fetchEventById() {
  //   await fetch(`${serverEnvironment}/discord/${eventId}`, {
  //     method: 'GET',
  //     headers: { 'Content-Type': 'application/json' },
  //   })
  //     .then(async (data) => {
  //       const response = await data.json();
  //       console.log('response', response);
  //       console.log('response', response.event._id);

  //       if (response.event._id) {
  //       }

  //       return response;
  //     })
  //     .catch((err) => console.error(err));
  // }
  // // useEffect(() => {
  // //   // Save gameId to localStorage, state management, or any other preferred method

  // //   localStorage.setItem('gameId', gameId);
  // //   console.log('hello');

  // //   // Redirect to the 'games' path
  // //   navigate(`/games`);
  // // }, [gameId, navigate]);

  // useEffect(() => {
  //   fetchEventById();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  return (
    <div>
      <Navbar />
      <Container style={{ color: 'white' }}>
        <br />
        <Typography component="h4" variant="h4" align="center" gutterBottom>
          <span className="montserrat-bold" style={{}}>
            PAGE OR EVENT NOT FOUND.
          </span>
        </Typography>
        <Typography variant="h5" align="center" paragraph>
          <span className="montserrat-bold" style={{}}>
          </span>
        </Typography>
      </Container>
    </div>
  );
};

export default ErrorPage;
