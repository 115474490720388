import {
  Dialog,
  DialogTitle,
  List,
  Typography,
  Divider,
  Avatar,
  ListItemAvatar,
  ListItem,
  Chip,
  ListItemText,
  TextField,
  Button,
} from '@mui/material';
import { Diamond, AccountCircle } from '@mui/icons-material';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import ReactGA from 'react-ga';
import {
  serverEnvironment,
  defaultInscriptionIds,
} from 'configs/app-config.js';
import { shareOnTwitter } from '../../util/functions';
import { collectionLabels } from '../../configs/collectionLabels';

export default function LeaderboardDialog(props) {
  ReactGA.initialize('G-RF6770D6JZ');
  const [openDialog, setOpenDialog] = useState(false);
  const [histories, setHistories] = useState([]);
  const user = useSelector((state) => state.user);
  const walletAddress = user && user.ordinalAddress;
  const userAgent = navigator.userAgent.toLowerCase();
  const isMobileDevice = /mobile/i.test(userAgent);
  const allCollectionlabels = collectionLabels();

  // Fetch event by event id
  async function fetchHistoriesWithHighestScores(gameName) {
    await fetch(`${serverEnvironment}/play/scores`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ gameName }),
    })
      .then(async (data) => {
        const response = await data.json();
        setHistories(response);
      })
      .catch((err) => console.error(err));
  }

  // Fetch event by event id
  async function updateUserName(userName) {
    await fetch(`${serverEnvironment}/play/scores`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ userName }),
    })
      .then(async (data) => {
        const response = await data.json();
        setHistories(response);
      })
      .catch((err) => console.error(err));
  }

  useEffect(() => {
    setOpenDialog(props.open);
    if (props.open) {
      fetchHistoriesWithHighestScores(props.gameName);
    }
  }, [props.open, props.selected]);

  const handleClose = () => {
    setOpenDialog(false);
    props.onClose();
  };

  return (
    <Dialog open={openDialog} onClose={handleClose}>
      <DialogTitle style={{ maxWidth: 1000 }}>Leaderboard</DialogTitle>
      <Divider />
      <List sx={{ bgcolor: 'background.paper' }}>
        {histories &&
          histories.map((history, index) => (
            <React.Fragment key={index}>
              <ListItem sx={{ pr: 3.5 }}>
                {index < 3 && (
                  <Diamond
                    style={{
                      color: 'red',
                      marginLeft: isMobileDevice ? 0 : 10,
                      width: isMobileDevice ? 15 : '',
                    }}
                  />
                )}
                {walletAddress &&
                  (walletAddress === history.ordinalAddress ||
                    walletAddress === history.ethAddress) && (
                    <Chip
                      sx={{
                        mx: 1,
                      }}
                      label="YOU"
                      color="primary"
                    />
                  )}
                <Chip
                  sx={{
                    mx: 1,
                  }}
                  label={index + 1}
                  color="primary"
                />
                <ListItemAvatar
                  sx={{
                    mx: 1,
                  }}
                >
                  <div
                    id={`${history.collectionName}-${history.inscriptionId}`}
                  >
                    <Avatar
                      sx={{
                        height: isMobileDevice ? 40 : 60,
                        width: isMobileDevice ? 40 : 60,
                      }}
                      alt={allCollectionlabels[history.collectionName]}
                      src={
                        !history.tokenId
                          ? `/assets/collections/${
                              history.collectionName === 'doge'
                                ? 'dogepunks'
                                : history.collectionName
                            }/${history.collectionName}_${
                              history.collectionNumber
                            }_${history.inscriptionNumber}_${
                              history.inscriptionId
                            }.png`
                          : `/assets/collections/${history.collectionName
                            }/${history.collectionName}_${
                              history.collectionNumber
                            }_${history.tokenId}.png`
                      }
                    />
                  </div>
                </ListItemAvatar>
                <ListItemText
                  primary={`${
                    history.userName
                      ? history.userName
                      : history.ordinalAddress || history.ethAddress
                      ? `${(history.ordinalAddress || history.ethAddress).slice(
                          0,
                          4
                        )}....${(
                          history.ordinalAddress || history.ethAddress
                        ).slice(-4)}`
                      : 'anonymous'
                  } - ${history.score}`}
                  primaryTypographyProps={{
                    fontSize: isMobileDevice ? '15px' : '20px',
                    fontWeight: 'bold',
                  }}
                  secondary={
                    <React.Fragment>
                      <Typography
                        sx={{ display: 'inline' }}
                        component="span"
                        variant="body2"
                        color="text.primary"
                      >
                        {allCollectionlabels[history.collectionName]} #
                        {history.collectionNumber}
                      </Typography>

                      {/* {history.ordinalAddress &&
                        ` (${history.ordinalAddress.slice(
                          0,
                          4
                        )}....${history.ordinalAddress.slice(-4)})`} */}
                    </React.Fragment>
                  }
                />

                {walletAddress &&
                  (walletAddress === history.ordinalAddress ||
                    walletAddress === history.ethAddress) && (
                    <img
                      src="../assets/twitter_color_resized.png"
                      style={{
                        color: 'red',
                        cursor: 'pointer',
                      }}
                      alt="twitter"
                      onClick={() => {
                        ReactGA.event({
                          category: 'button_click',
                          action: 'share_score',
                          label: 'ShareScore',
                        });
                        shareOnTwitter(
                          history,
                          walletAddress &&
                            (!defaultInscriptionIds.includes(
                              history.inscriptionId
                            ) ||
                              history.tokenId)
                            ? 'my'
                            : 'default'
                        );
                      }}
                    />
                  )}
                {/* <img
                  src="../assets/twitter_color_resized.png"
                  style={{
                    color: 'red',
                    cursor: 'pointer',
                  }}
                  alt="twitter"
                  onClick={() => {
                    ReactGA.event({
                      category: 'button_click',
                      action: 'share_score',
                      label: 'ShareScore',
                    });
                    shareOnTwitter(
                      history,
                      ordinalAddress &&
                        !defaultInscriptionIds.includes(history.inscriptionId)
                        ? 'my'
                        : 'default'
                    );
                  }}
                /> */}
                {/* <AccountCircle
                  style={{
                    color: 'red',
                    marginLeft: isMobileDevice ? 0 : 10,
                    width: isMobileDevice ? 15 : '',
                  }}
                /> */}
              </ListItem>
              {/* <ListItem sx={{ pr: 3.5 }}>
                <TextField id="outlined-basic" label="Outlined" variant="outlined" />
                <Button variant="contained">
                    Save
                </Button>
              </ListItem> */}
              <Divider />
            </React.Fragment>
          ))}
      </List>
    </Dialog>
  );
}
