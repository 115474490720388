import {
  Box,
  Typography,
  Avatar,
  Container,
  ListItem,
  List,
  Divider,
  ListItemText,
  ListItemAvatar,
  Chip,
  Grid,
  Button,
  TextField,
} from '@mui/material';
import { Diamond, AccountCircle } from '@mui/icons-material';
import { useNavigate, useParams } from 'react-router-dom';
import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  serverEnvironment,
  defaultInscriptionIds,
} from 'configs/app-config.js';
import { eventShareOnTwitter } from '../../util/functions';
import Navbar from 'components/navbar';
import { setUserName } from 'state';
import ReactGA from 'react-ga';

const EventLeaderboardPage = () => {
  const dispatch = useDispatch();
  ReactGA.initialize('G-RF6770D6JZ');
  // Redux State
  const user = useSelector((state) => state.user);
  const ordinalAddress = user && user.ordinalAddress;
  const [topScores, setTopScores] = useState([]);
  const [mostPlayed, setMostPlayed] = useState([]);
  const [inputValue, setInputValue] = useState(user.userName);
  const navigate = useNavigate();
  const userAgent = navigator.userAgent.toLowerCase();
  const isMobileDevice = /mobile/i.test(userAgent);

  function sortByScoreAndDate(a, b) {
    const aReference = a.score || a.count;
    const bReference = b.score || b.count;
    if (aReference === bReference) {
      // If scores are equal, sort by date in ascending order (earlier first)
      return new Date(a.createdAt) - new Date(b.createdAt);
    }
    // Sort by score in descending order (higher first)
    return bReference - aReference;
  }

  const fetchHistoriesWithHighestScores = async () => {
    try {
      const response = await fetch(`${serverEnvironment}/play/scores/event`);
      const data = await response.json();

      const sortedScores = data.topScores.sort(sortByScoreAndDate);

      setTopScores(sortedScores);
    } catch (err) {
      console.error(err);
    }
  };

  // async function fetchHistoriesWithHighestScores(gameName) {
  //   await fetch(`${serverEnvironment}/play/scores/event`, {
  //     method: 'POST',
  //     headers: { 'Content-Type': 'application/json' },
  //     body: JSON.stringify({ gameName }),
  //   })
  //     .then(async (data) => {
  //       const response = await data.json();
  //       const sortedScores = data.topScores.sort(sortByScoreAndDate);

  //       console.log("sortedScores", sortedScores)
  //       setTopScores(sortedScores);
  //     })
  //     .catch((err) => console.error(err));
  // }

  // Google analytics Page View
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
    fetchHistoriesWithHighestScores();
  }, []);

  // // Manual input of discord handle
  // const handleChange = (evt) => {
  //   console.log('evt.target.value', evt.target.value);
  //   setInputValue(evt.target.value);
  // };

  // // Manual input of discord handle
  // const saveUserName = (evt) => {
  //   try {
  //     dispatch(setUserName({ userName: inputValue }));
  //   } catch (error) {
  //     console.log('error', error);
  //   }
  //   // setInputValue(evt.target.value);
  // };

  // Just in case we need the mobile version even a simple one
  // const isNonMobileScreens = useMediaQuery('(min-width:1000px)');
  return (
    <div>
      <Navbar />
      <br />
      <Container style={{ maxWidth: isMobileDevice ? '100%' : '40vw' }}>
        <Grid container spacing={2}>
          {/* <Grid item xs={12}> */}
          <Grid item xs={12}>
            <Typography variant="h5" align="center" paragraph>
              <span className="montserrat-bold" style={{}}>
                HIGHEST SCORES
              </span>
            </Typography>
            <Box sx={{ flexGrow: 1 }}>
              <List style={{ backgroundColor: `rgb(255, 255, 255, 0.9)` }}>
                {topScores &&
                  topScores.map((history, index) => (
                    <React.Fragment key={index}>
                      <ListItem sx={{ pr: 3.5 }}>
                        {index < 3 && (
                          <Diamond
                            style={{
                              color: 'red',
                              marginLeft: isMobileDevice ? 0 : 10,
                              width: isMobileDevice ? 15 : '',
                            }}
                          />
                        )}
                        {ordinalAddress &&
                          ordinalAddress === history.ordinalAddress && (
                            <Chip
                              sx={{
                                mx: 1,
                              }}
                              label="YOU"
                              color="primary"
                            />
                          )}
                        <Chip
                          sx={{
                            mx: 1,
                          }}
                          label={index + 1}
                          color="primary"
                        />
                        <ListItemAvatar
                          sx={{
                            mx: 1,
                          }}
                        >
                          <div
                            id={`${history.collectionName}-${history.inscriptionId}`}
                          >
                            <Avatar
                              sx={{
                                height: 60,
                                width: 60,
                              }}
                              alt={`${history.collectionName}`}
                              src={`/assets/collections/${history.collectionName}/${history.collectionName}_${history.collectionNumber}_${history.inscriptionNumber}_${history.inscriptionId}.png`}
                            />
                          </div>
                        </ListItemAvatar>
                        <ListItemText
                          primary={`${
                            history.userName
                              ? history.userName
                              : ` ${history.ordinalAddress.slice(
                                  0,
                                  4
                                )}....${history.ordinalAddress.slice(-4)}`
                          } - ${history.score}`}
                          primaryTypographyProps={{
                            fontSize: '20px',
                            fontWeight: 'bold',
                          }}
                          secondary={
                            <React.Fragment>
                              <Typography
                                sx={{ display: 'inline' }}
                                component="span"
                                variant="body2"
                                color="text.primary"
                              >
                                Pixelated OMB #{history.collectionNumber}
                              </Typography>

                              {history.ordinalAddress &&
                                ` (${history.ordinalAddress.slice(
                                  0,
                                  4
                                )}....${history.ordinalAddress.slice(-4)})`}
                            </React.Fragment>
                          }
                        />

                        {ordinalAddress &&
                          ordinalAddress === history.ordinalAddress && (
                            <img
                              src="../assets/twitter_color_resized.png"
                              style={{
                                color: 'red',
                                cursor: 'pointer',
                              }}
                              alt="twitter"
                              onClick={() => {
                                ReactGA.event({
                                  category: 'button_click',
                                  action: 'share_score',
                                  label: 'ShareScore',
                                });
                                eventShareOnTwitter(
                                  history,
                                  ordinalAddress &&
                                    !defaultInscriptionIds.includes(
                                      history.inscriptionId
                                    )
                                    ? 'my'
                                    : 'default',
                                  'highest score',
                                  index + 1
                                );
                              }}
                            />
                          )}
                        {/* <img
                          src="../assets/twitter_color_resized.png"
                          style={{
                            color: 'red',
                            cursor: 'pointer',
                          }}
                          alt="twitter"
                          onClick={() => {
                            ReactGA.event({
                              category: 'button_click',
                              action: 'share_score',
                              label: 'ShareScore',
                            });
                            eventShareOnTwitter(
                              history,
                              ordinalAddress &&
                                !defaultInscriptionIds.includes(
                                  history.inscriptionId
                                )
                                ? 'my'
                                : 'default',
                              'highest score',
                              index + 1
                            );
                          }}
                        /> */}
                        {/* <AccountCircle
                          style={{
                            color: 'red',
                            marginLeft: isMobileDevice ? 0 : 10,
                            width: isMobileDevice ? 15 : '',
                          }}
                        /> */}
                      </ListItem>
                      {/* <ListItem sx={{ pr: 3.5 }}>
                        <TextField
                          id="outlined-basic"
                          label="User Name"
                          variant="outlined"
                          value={inputValue}
                          onChange={handleChange}
                        />
                        <Button variant="contained">Save</Button>
                      </ListItem> */}
                      <Divider />
                    </React.Fragment>
                  ))}
              </List>
            </Box>
          </Grid>
          {/* <Grid item xs={12} sm={6}>
            <Typography variant="h5" align="center" paragraph>
              <span className="montserrat-bold" style={{}}>
                MOST PLAYED
              </span>
            </Typography>
            <Box sx={{ flexGrow: 1 }}>
              <List style={{ backgroundColor: `rgb(255, 255, 255, 0.9)` }}>
                {mostPlayed &&
                  mostPlayed.map((history, index) => (
                    <React.Fragment key={index}>
                      <ListItem sx={{ pr: 3.5 }}>
                        {index < 3 && (
                          <Diamond
                            style={{
                              color: 'red',
                              marginLeft: 10,
                            }}
                          />
                        )}
                        {ordinalAddress && ordinalAddress === history._id && (
                          <Chip
                            sx={{
                              mx: 1,
                            }}
                            label="YOU"
                            color="primary"
                          />
                        )}
                        <Chip
                          sx={{
                            mx: 1,
                          }}
                          label={index + 1}
                          color="primary"
                        />
                        <ListItemAvatar
                          sx={{
                            mx: 1,
                          }}
                        >
                          <div id={`doge-${history.inscriptionId}`}>
                            <Avatar
                              sx={{
                                height: 60,
                                width: 60,
                              }}
                              alt="DogePunk"
                              src={`/assets/collections/dogepunks/doge_${history.collectionNumber}_${history.inscriptionNumber}_${history.inscriptionId}.png`}
                            />
                          </div>
                        </ListItemAvatar>
                        <ListItemText
                          primary={`${`${history._id.slice(
                            0,
                            4
                          )}....${history._id.slice(-4)}`} - ${
                            history.count
                          } Times`}
                          primaryTypographyProps={{
                            fontSize: '20px',
                            fontWeight: 'bold',
                          }}
                          secondary={
                            <React.Fragment>
                              <Typography
                                sx={{ display: 'inline' }}
                                component="span"
                                variant="body2"
                                color="text.primary"
                              >
                                DogePunks #{history.collectionNumber}
                              </Typography>
                            </React.Fragment>
                          }
                        />

                        {ordinalAddress && ordinalAddress === history._id && (
                          <img
                            src="../assets/twitter_color_resized.png"
                            style={{
                              color: 'red',
                              cursor: 'pointer',
                            }}
                            alt="twitter"
                            onClick={() => {
                              ReactGA.event({
                                category: 'button_click',
                                action: 'share_score',
                                label: 'ShareScore',
                              });
                              eventShareOnTwitter(
                                history,
                                ordinalAddress && !defaultInscriptionIds.includes(history.inscriptionId) ? 'my' : 'default',
                                'most played',
                                index + 1
                              );
                            }}
                          />
                        )}
                      </ListItem>
                      <Divider />
                    </React.Fragment>
                  ))}
              </List>
            </Box>
          </Grid> */}
        </Grid>
      </Container>

      <Container style={{ color: 'white' }}>
        <br />
        <Typography component="h4" variant="h4" align="center" gutterBottom>
          <span className="montserrat-bold" style={{}}>
            WORLD'S FIRST BTC ORDINALS PLAYABLE GAME
          </span>
        </Typography>
        <Typography variant="h5" align="center" paragraph>
          <span className="montserrat-bold" style={{}}>
            MORE GAMES AND COLLABS COMING SOON...
          </span>
        </Typography>
      </Container>
    </div>
  );
};

export default EventLeaderboardPage;
