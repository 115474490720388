import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { serverEnvironment } from 'configs/app-config.js';
import DiscordSigninDialog from 'components/discordSigninDialog';
import { setDiscord } from 'state';

// Decryption
function decrypt(str, secretKey) {
  let shift = Array.from(secretKey).reduce((acc, char) => acc + char.charCodeAt(0), 0);
  return Array.from(str).map(char => {
      let code = char.charCodeAt(0);
      if ((code >= 65 && code <= 90) || (code >= 97 && code <= 122)) {
          let limit = code < 97 ? 65 : 97;
          code -= shift % 26;
          if (code < limit) {
              code += 26;
          }
      }
      return String.fromCharCode(code);
  }).join('');
}

const GameRedirectPage = () => {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const { eventId } = useParams();
  const [discordSigninDialogOpen, setDiscordSigninDialogOpen] = useState(false);
  const [event, setEvent] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search); // Access query parameters

  const encrypted = queryParams.get('id');
  const secretKey = '1234567890123456';
  // Decryption
  const decodedMessage = decrypt(encrypted, secretKey);
  const originalText = decodedMessage.split('-');
  const discordId = originalText[0];
  const username = originalText[1];
  const discriminator = originalText[2];

  // Fetch event by event id
  async function fetchEventById() {
    await fetch(`${serverEnvironment}/discord/${eventId}`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    })
      .then(async (data) => {
        const response = await data.json();
        const currentDate = new Date();

        setEvent(response.event);

        // Check if the event is active and current date is within the start and end date
        if (
          !(
            response.event &&
            response.event._id &&
            response.event.status === 'active'
          ) ||
          !(
            currentDate >= new Date(response.event.startDate) &&
            currentDate <= new Date(response.event.endDate)
          )
        ) {
          navigate(`/error`);
        } else {
          if (response.event.open_registration) {
            dispatch(
              setDiscord({
                discord: {
                  discordId: discordId,
                  discordName: username,
                  discordDiscriminator: discriminator,
                },
              })
            );
          } else if (
            response.event.participants &&
            response.event.participants.length > 0
          ) {
            for (const participant of response.event.participants) {
              if (participant.id === discordId) {
                dispatch(
                  setDiscord({
                    discord: {
                      discordId: participant.id,
                      discordName: participant.username,
                      discordDiscriminator: participant.discriminator,
                    },
                  })
                );
                break;
              }
            }
          }
          setDiscordSigninDialogOpen(true);
        }
      })
      .catch((err) => console.error(err));
  }

  useEffect(() => {
    if (eventId) {
      fetchEventById();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <DiscordSigninDialog
        open={discordSigninDialogOpen}
        style={{ width: '1000px' }}
        event={event}
      />
    </div>
  );
};

export default GameRedirectPage;
