export const collectionLabels = () => {
    const list = {
        'doge': 'DogePunks',
        'ordinalrobots': 'Ordinal Robots',
        'bitcoinpunks': 'Bitcoin Punks',
        'bitcoinbulls': 'Bitcoin Bulls',
        'bitcoinbandits': 'Bitcoin Bandits',
        'ordibots': 'Ordibots',
        'ordiblocks': 'Ordiblocks',
        'ordinaleggs': 'Ordinals Egg',
        'ordinaldragons': 'Ordinal Dragons',
        'toxinstemplar': 'Toxins Templar',
        'satoshit': 'Satoshit',
        'bitcoinchimps': 'Bitcoin Chimps',
        'bapc': 'BAPC',
        'pomb': 'Pixelated OMB',
        'lazysats': 'Lazy Sats',
        'ordinalunis': 'Ordinal Unis',
        'anons': 'Ordinal Anons',
        'ordinalfomojis': 'Ordinal Fomojis',
        'degenape': 'Degen Ape Squad',
        'taprootcows': 'Taproot Cows',
        'sloth': 'SlothsHQ',
        'omc': 'Ordinal Monkey Club',
        'ordinalmages': 'Ordinal Mages',
        'inscribables': 'Inscribables',
        'btcwhales': 'Bitcoin Whales',
        'pioneers': 'Pioneers',
        'npc': 'NPC Ordinals',
        'aperunners': 'Ape Runners',
        'cpc': 'Cool Punk Cats',
        'bapcgenesis': 'Bored Ape Pixel Club Genesis',
        'tribeordinals': 'Tribe Ordinals',
        'tribeodyssey': 'Tribe Odyssey',
        'btcbabbies': 'Bitcoin Babbies',
        'skelly': 'Skelly Uni',
        'almc': 'Awkward Look Monkey Club',
        'tinyminotaurs': 'Tiny Minotaurs',
        'bob': 'Bob on Bitcoin',
        'psychlops': 'BTC Psychlopsnft',
        'btcbuds': 'Bitcoin Buds',
        'taruguitos': 'The Taruguitos',
        'clownmigos': 'Clownmigos',
        'toshiorder': 'The Toshi Order',
    }

    return list;
}