import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomePage from "pages/homePage";
import GamePage from "pages/gamePage";
import GameRedirectPage from "pages/gameRedirectPage";
import DiscordRedirectPage from "pages/discordRedirectPage";
import DiscordBotPage from "pages/discordBotPage";
import EventLeaderboardPage from "pages/eventLeaderboardPage";
import PublicLeaderboardPage from "pages/publicLeaderboardPage";
import EventPage from "pages/eventPage";
import ErrorPage from "pages/errorPage";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import ReactGA from 'react-ga';
import "./App.css";

function App() {
  const theme = createTheme();
  ReactGA.initialize('G-RF6770D6JZ');
  ReactGA.pageview(window.location.pathname + window.location.search);

  return (
    <div className="app">
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/games/:gameName" element={<GamePage />} />
            <Route path="/discord_login" element={<DiscordRedirectPage />} />
            <Route path="/discord" element={<DiscordBotPage />} />
            <Route path="/discord_bot" element={<DiscordBotPage />} />
            <Route path="/event/:eventId" element={<GameRedirectPage />} />
            <Route path="/pixelatedOMB" element={<EventLeaderboardPage />} />
            <Route path="/leaderboard" element={<PublicLeaderboardPage />} />
            <Route path="/error" element={<ErrorPage />} />
            <Route path="/*" element={<ErrorPage />} />
            {/* <Route
              path="/profile/:userId"
              element={isAuth ? <ProfilePage /> : <Navigate to="/" />}
            /> */}
          </Routes>
        </ThemeProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
