// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore"
import { getStorage } from 'firebase/storage'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyAiXfjTNHInLWuWwPBloxil0kxgvg-7-Fk",
    authDomain: "ordinalsplay-ef807.firebaseapp.com",
    projectId: "ordinalsplay-ef807",
    storageBucket: "ordinalsplay-ef807.appspot.com",
    messagingSenderId: "838265778220",
    appId: "1:838265778220:web:b682a40252856aed4aa189",
    measurementId: "G-Y1E83X720S"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const analytics = getAnalytics(app);
export const db = getFirestore(app);
export const storage = getStorage();

