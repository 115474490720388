import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Tooltip,
  Menu,
  MenuItem,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  ListItemIcon,
  Avatar,
  Dialog,
  DialogTitle,
  Box,
  IconButton,
  Divider,
  Badge,
} from '@mui/material';
import { Menu as MenuIcon, Twitter as TwitterIcon } from '@mui/icons-material';
import { FaDiscord, FaSignOutAlt, FaTwitter } from 'react-icons/fa';
import { getAddress, signMessage } from 'sats-connect';
import { useUnityContext } from 'react-unity-webgl';
import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setLogin, setLogout } from 'state';
import { useNavigate } from 'react-router-dom';
import ReactGA from 'react-ga';
import { screenBreakpoints, serverEnvironment } from 'configs/app-config.js';
import { AppConfig, UserSession, showConnect } from '@stacks/connect';
import { StacksMainnet } from '@stacks/network';
import WarningDialog from '../../components/warningDialog';
import { ethers } from 'ethers';

const Navbar = () => {
  const dispatch = useDispatch();
  const { addEventListener, removeEventListener } = useUnityContext({
    loaderUrl: 'Build/build_test_webgl.loader.js',
    dataUrl: 'Build/build_test_webgl.data',
    frameworkUrl: 'Build/build_test_webgl.framework.js',
    codeUrl: 'Build/build_test_webgl.wasm',
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const [openConnectWalletDialog, setOpenConnectWalletDialog] = useState(false);
  const [openWarningDialog, setOpenWarningDialog] = useState(false);
  const user = useSelector((state) => state.user);
  const ordinalAddress = (user && user.ordinalAddress) || ''; //useSelector((state) => state.user.ordinalAddress);
  const paymentAddress = (user && user.paymentAddress) || ''; //useSelector((state) => state.user.paymentAddress);
  const ethAddress = (user && user.ethAddress) || ''; //useSelector((state) => state.user.ordinalAddress);
  const walletAddress = ordinalAddress || ethAddress;
  // const ordinalAddress = useSelector((state) => state.ordinalAddress);
  // const paymentAddress = useSelector((state) => state.paymentAddress);
  const smallScreen = window.innerWidth < screenBreakpoints.md;
  const [isMobile, setIsMobile] = useState(false);
  const userAgent = navigator.userAgent.toLowerCase();
  const isMobileDevice = /mobile/i.test(userAgent);

  useEffect(() => {
    setIsMobile(isMobileDevice);
  }, []);

  const wallets = isMobileDevice
    ? [
        {
          name: 'Xverse',
          image: '/assets/xverse.png',
        },
        {
          name: 'Metamask',
          image: '/assets/metamask.png',
        },
      ]
    : [
        {
          name: 'Xverse',
          image: '/assets/xverse.png',
        },
        {
          name: 'Hiro Wallet',
          image: '/assets/hiro_wallet.jpeg',
        },
        {
          name: 'Metamask',
          image: '/assets/metamask.png',
        },
        // {
        //   name: 'Phantom',
        //   image: '/assets/phantom.png',
        // },
      ];

  // Hiro wallet
  const appConfig = new AppConfig();
  const userSession = new UserSession({ appConfig });

  const navigate = useNavigate();
  const open = Boolean(anchorEl);
  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
    ReactGA.event({
      category: 'button_click',
      action: 'signed_connect_button_click',
      label: 'SignedConnectButtonClick',
    });
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // Get Wallet addresses from wallets
  const handleGetAddress = useCallback(
    async (walletName) => {
      const registerUser = async (ordinalAddress, paymentAddress) => {
        await fetch(`${serverEnvironment}/auth/login`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            ordinalAddress,
            paymentAddress,
          }),
        })
          .then(async (data) => {
            const response = await data.json();
            // onSignMessageClick(response.user.ordinalAddress, 'from register');
            dispatch(setLogin({ user: response.user }));
          })
          .catch((err) => console.error(err));
      };

      const registerEthUser = async (ethAddress) => {
        await fetch(`${serverEnvironment}/auth/login`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            ethAddress,
          }),
        })
          .then(async (data) => {
            const response = await data.json();
            // onSignMessageClick(response.user.ordinalAddress, 'from register');
            dispatch(setLogin({ user: response.user }));
          })
          .catch((err) => console.error(err));
      };

      const getAddressOptions = {
        payload: {
          purposes: ['ordinals', 'payment'],
          message: 'Address for receiving Ordinals and payments',
          network: {
            type: 'Mainnet',
          },
        },
        onFinish: (response) => {
          // dispatch(
          //   setAddresses({
          //     ordinalAddress: response.addresses[0].address,
          //     paymentAddress: response.addresses[1].address,
          //   })
          // );
          registerUser(
            response.addresses[0].address,
            response.addresses[1].address
          );
        },
      };

      if (walletName === 'Xverse') {
        await getAddress(getAddressOptions);
      } else if (walletName === 'Hiro Wallet') {
        var resolve = (paymentAddress, ordinalAddress) => {
          // dispatch(
          //   setAddresses({
          //     ordinalAddress,
          //     paymentAddress,
          //   })
          // );
          registerUser(ordinalAddress, paymentAddress);
        };

        if (!userSession.isUserSignedIn()) {
          showConnect({
            userSession,
            network: StacksMainnet,
            appDetails: {
              name: 'Ordinals Play',
              icon: 'https://firebasestorage.googleapis.com/v0/b/ordinalsplay-ef807.appspot.com/o/ordinals_play_logo_no_text.png?alt=media&token=6f61113c-bcba-45fd-bf33-bfb8b3bb7e8b',
            },
            onFinish: () => {
              resolve(
                userSession.loadUserData().profile.btcAddress.p2wpkh.mainnet,
                userSession.loadUserData().profile.btcAddress.p2tr.mainnet
              );
            },
          });
        }
      } else if (walletName === 'Metamask') {
        if (window.ethereum) {
          async function requestAccount() {
            if (window.ethereum) {
              const message = 'Sign in to Ordinals Play';
              try {
                const provider = new ethers.BrowserProvider(window.ethereum)
                const signer = await provider.getSigner();
                const address = await signer.getAddress();
                await signer.signMessage(message);

                registerEthUser(address);
              } catch (error) {
                console.log('Error connecting...');
              }
            } else {
              alert('Meta Mask not detected');
            }
          }
          await requestAccount();
        }
      }
    },
    [dispatch, userSession]
  );

  const onSignMessageClick = async (userOrdinalAddress, from) => {
    const signMessageOptions = {
      payload: {
        network: {
          type: 'Mainnet',
        },
        address: userOrdinalAddress,
        message: 'Sign In to OrdinalsPlay',
      },
      onFinish: (response) => {
        console.log(response);
      },
      onCancel: () => alert('Canceled'),
    };
    await signMessage(signMessageOptions);
  };

  // Connect wallet from Unity
  const connectWalletFromUnity = useCallback(async () => {
    ReactGA.event({
      category: 'trigger_unity',
      action: 'connect_wallet',
      label: 'ConnectWallet',
    });
    setOpenConnectWalletDialog(true);
  }, [setOpenConnectWalletDialog]);

  // WalletLogin is the name of the function in React.jslib file in Unity
  useEffect(() => {
    addEventListener('WalletLogin', connectWalletFromUnity);
    return () => {
      removeEventListener('WalletLogin', connectWalletFromUnity);
    };
  }, [addEventListener, removeEventListener, connectWalletFromUnity]);

  // Connect wallet
  const connectWallet = async (walletName) => {
    ReactGA.event({
      category: 'button_click',
      action: 'connect_wallet',
      label: 'ConnectWallet',
    });
    await handleGetAddress(walletName);
    setOpenConnectWalletDialog(false);
  };

  // Sign out
  const signOut = () => {
    setAnchorEl(null);
    dispatch(setLogout());

    if (userSession.isUserSignedIn()) {
      userSession.signUserOut();
    }

    ReactGA.event({
      category: 'button_click',
      action: 'signed_sign_out_click',
      label: 'SignedSignOutClick',
    });
  };

  return (
    <React.Fragment>
      <WarningDialog
        open={openWarningDialog}
        onCloseWarningDialog={() => setOpenWarningDialog(false)}
      />
      <AppBar
        position="static"
        elevation={0}
        sx={{ borderBottom: (theme) => `1px solid ${theme.palette.divider}` }}
        style={{ background: 'transparent' }}
      >
        <Toolbar sx={{ flexWrap: 'wrap' }}>
          <img
            src={`/assets/logo1.png`}
            alt="ordinalsplay"
            style={{
              width: '20px',
              marginRight: 10,
              marginTop: -4,
              cursor: 'pointer',
            }}
            onClick={() => {
              navigate(`/`);
              ReactGA.event({
                category: 'routing',
                action: 'logo_click_home_page',
                label: 'LogoClickHomePage',
              });
            }}
          />
          <Typography
            variant={smallScreen ? '' : 'h6'}
            color="inherit"
            noWrap
            style={{
              cursor: 'pointer',
            }}
            sx={{ flexGrow: 1 }}
            onClick={() => {
              navigate(`/`);
              ReactGA.event({
                category: 'routing',
                action: 'name_click_home_page',
                label: 'NameClickHomePage',
              });
            }}
          >
            <span className="montserrat-bold">ORDINALS PLAY</span>
          </Typography>
          <Box sx={{ flexGrow: 24, display: { xs: 'none', md: 'flex' } }}>
            <Typography
              align="center"
              gutterBottom
              sx={{ my: 2, color: 'white', display: 'block', mr: 2 }}
              style={{
                cursor: 'pointer',
              }}
            >
              <span
                className="montserrat-bold"
                onClick={() => navigate('/discord')}
              >
                DISCORD BOT
              </span>
            </Typography>
            <FaDiscord
              onClick={() =>
                window.open('https://discord.gg/VxNDzyHX', '_blank')
              }
              style={{
                fontSize: '24px',
                color: 'white',
                marginTop: '15px',
                cursor: 'pointer',
                marginRight: '10px',
              }}
            />
            <FaTwitter
              onClick={() =>
                window.open('https://twitter.com/OrdinalsPlay', '_blank')
              }
              style={{
                fontSize: '24px',
                color: 'white',
                marginTop: '15px',
                cursor: 'pointer',
              }}
            />
            {/* <Typography
              align="center"
              gutterBottom
              sx={{ my: 2, color: 'white', display: 'block', mx: 2 }}
            >
              <span className="montserrat-bold">FAQ</span>
            </Typography> */}
          </Box>

          <Box sx={{ flexGrow: 0, display: { xs: 'none', md: 'flex' } }}>
            {walletAddress && (
              <React.Fragment>
                <Typography
                  align="center"
                  gutterBottom
                  sx={{ mx: 2, color: 'white', display: 'block' }}
                >
                  <span className="montserrat-bold">$ORPL: {user.points}</span>
                </Typography>
              </React.Fragment>
            )}
            <Typography
              align="center"
              gutterBottom
              sx={{ mx: 2, color: 'white', display: 'block' }}
              onClick={() => navigate('/leaderboard')}
            >
              <span style={{ cursor: 'pointer' }} className="montserrat-bold">
                WEEKLY LEADERBOARD
              </span>
            </Typography>
            {/* <Badge
              badgeContent={'HOT'}
              color={'error'}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              <Typography
                align="center"
                gutterBottom
                sx={{ mx: 2, color: 'white', display: 'block' }}
                onClick={() => navigate('/leaderboard')}
              >
                <span style={{ cursor: 'pointer' }} className="montserrat-bold">
                  WEEKLY LEADERBOARD
                </span>
              </Typography>
            </Badge> */}

            {/* <Typography
              align="center"
              gutterBottom
              sx={{ mx: 2, color: 'white', display: 'block' }}
              onClick={() => {
                navigate(`/event/64650939566b952cbfe95eae`);
              }}
            >
              <span
                style={{
                  cursor: 'pointer',
                }}
                className="montserrat-bold"
              >
                Event
              </span>
            </Typography>
            <Typography
              align="center"
              gutterBottom
              sx={{ mx: 2, color: 'white', display: 'block' }}
              onClick={() => {
                navigate(`/game/1`);
              }}
            >
              <span
                style={{
                  cursor: 'pointer',
                }}
                className="montserrat-bold"
              >
                Game one
              </span>
            </Typography> */}
            {/* <Typography
              align="center"
              gutterBottom
              sx={{ mx: 2, color: 'white', display: 'block' }}
              onClick={() => {
                navigate(`/event`);
              }}
            >
              <span
                style={{
                  cursor: 'pointer',
                }}
                className="montserrat-bold"
              >
                ORDINAL ROBOTS EVENT
              </span>
            </Typography> */}
          </Box>
          {!isMobile && (
            <Button
              href="#"
              variant="contained"
              sx={{
                my: 1,
                ml: smallScreen ? 0 : 1.5,
                pt: smallScreen ? 1 : 1.5,
              }}
              style={{ backgroundColor: '#7289DA' }}
              onClick={() => {
                // setOpenWarningDialog(true);
                window.open(
                  'https://discord.com/oauth2/authorize?client_id=1107287420673720440&scope=bot&permissions=138513139776',
                  '_blank'
                );
              }}
            >
              <FaDiscord
                style={{
                  fontSize: '24px',
                  color: 'white',
                  marginBottom: 7,
                  marginRight: 5,
                }}
              />
              <Typography
                component={smallScreen ? '' : 'h6'}
                variant={smallScreen ? '' : 'h6'}
                align="center"
                gutterBottom
              >
                <span
                  style={{ fontSize: smallScreen ? '10px' : '' }}
                  className="montserrat-bold"
                >
                  Add Discord Bot
                </span>
              </Typography>
            </Button>
          )}

          {isMobile ? (
            // Mobile Menu
            <React.Fragment>
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                href="#"
                variant="contained"
                aria-controls={open ? 'mobile-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                sx={{
                  my: 1,
                  mx: smallScreen ? 0 : 1.5,
                  pt: smallScreen ? 1 : 1.5,
                }}
                onClick={handleMenuClick}
              >
                <MenuIcon />
              </IconButton>{' '}
              <Menu
                id="mobile-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
                {/* <MenuItem
                  onClick={() => {
                    // setOpenWarningDialog(true);
                    // window.open(
                    //   'https://discord.com/oauth2/authorize?client_id=1107287420673720440&scope=bot&permissions=137439235136',
                    //   '_blank'
                    // );
                  }}
                >
                  Add Discord Bot
                </MenuItem> */}
                <MenuItem onClick={() => navigate('/leaderboard')}>
                  Weekly Leaderboard
                </MenuItem>
                <MenuItem onClick={() => navigate('/')}>Games</MenuItem>
                {!walletAddress ? (
                  <MenuItem onClick={() => setOpenConnectWalletDialog(true)}>
                    Connect Wallet
                  </MenuItem>
                ) : (
                  <MenuItem>
                    <Typography>
                      <span className="montserrat-bold">
                        $ORPL: {user.points}
                      </span>
                    </Typography>
                  </MenuItem>
                )}
                <Divider />
                <MenuItem onClick={() => navigate('/discord')}>
                  About Discord Bot
                </MenuItem>
                <MenuItem
                  onClick={() =>
                    window.open('https://twitter.com/OrdinalsPlay', '_blank')
                  }
                >
                  Twitter
                </MenuItem>
                <MenuItem
                  onClick={() =>
                    window.open('https://discord.gg/VxNDzyHX', '_blank')
                  }
                >
                  Discord
                </MenuItem>
                {/* <Divider />
                <MenuItem
                  onClick={() => {
                    navigate(`/event`);
                  }}
                >
                  Ordinal Robots Event
                </MenuItem> */}
                {walletAddress && <Divider />}
                {walletAddress && (
                  <MenuItem onClick={signOut}>Sign Out</MenuItem>
                )}
              </Menu>
            </React.Fragment>
          ) : (
            // Desktop
            <React.Fragment>
              {/* Signed In */}
              {walletAddress ? (
                <div>
                  <Button
                    href="#"
                    variant="contained"
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    sx={{
                      my: 1,
                      mx: smallScreen ? 0 : 1.5,
                      pt: smallScreen ? 1 : 1.5,
                    }}
                    style={{ backgroundColor: '#f2a900' }}
                    onClick={handleMenuClick}
                  >
                    <Typography
                      component={smallScreen ? '' : 'h6'}
                      variant={smallScreen ? '' : 'h6'}
                      align="center"
                      gutterBottom
                    >
                      <span
                        style={{ fontSize: smallScreen ? '10px' : '' }}
                        className="montserrat-bold"
                      >
                        {`${walletAddress.slice(0, 4)}....${walletAddress.slice(
                          -4
                        )}`}
                      </span>
                    </Typography>
                  </Button>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                      'aria-labelledby': 'basic-button',
                    }}
                  >
                    {/* <MenuItem
                      onClick={() => {
                        window.location.href =
                          'https://discord.com/api/oauth2/authorize?client_id=1107287420673720440&redirect_uri=http%3A%2F%2Flocalhost%3A8080%2Fauth%2Fdiscord_login&response_type=code&scope=identify%20guilds%20guilds.members.read';
                      }}
                      sx={{}}
                    >
                      <ListItemIcon>
                        <FaDiscord
                          style={{
                            fontSize: '24px',
                            color: '#7289DA',
                          }}
                        />
                      </ListItemIcon>
                      <ListItemText>
                        <span className="montserrat-medium" style={{}}>
                          Connect Discord
                        </span>
                      </ListItemText>
                    </MenuItem> */}
                    <MenuItem onClick={signOut}>
                      <ListItemIcon>
                        <FaSignOutAlt
                          style={{
                            fontSize: '20px',
                          }}
                        />
                      </ListItemIcon>
                      <ListItemText>
                        <span className="montserrat-medium">Sign Out</span>
                      </ListItemText>
                    </MenuItem>
                  </Menu>
                </div>
              ) : (
                // Not singed in
                <Tooltip title="Currently Supports Xverse Only">
                  <Button
                    href="#"
                    variant="contained"
                    sx={{
                      my: 1,
                      mx: smallScreen ? 0 : 1.5,
                      pt: smallScreen ? 1 : 1.5,
                    }}
                    style={{ backgroundColor: '#f2a900' }}
                    onClick={() => setOpenConnectWalletDialog(true)}
                  >
                    <Typography
                      component={smallScreen ? '' : 'h6'}
                      variant={smallScreen ? '' : 'h6'}
                      align="center"
                      gutterBottom
                    >
                      <span
                        style={{ fontSize: smallScreen ? '10px' : '' }}
                        className="montserrat-bold"
                      >
                        CONNECT WALLET
                      </span>
                    </Typography>
                  </Button>
                </Tooltip>
              )}
            </React.Fragment>
          )}
        </Toolbar>
      </AppBar>
      <Dialog
        open={openConnectWalletDialog}
        onClose={() => setOpenConnectWalletDialog(false)}
      >
        <DialogTitle style={{ width: isMobileDevice ? 300 : 400 }}>
          Connect Wallet
        </DialogTitle>
        <List sx={{ bgcolor: 'background.paper' }}>
          {wallets &&
            wallets.map((wallet, index) => (
              <React.Fragment key={index}>
                <ListItem
                  onClick={() => connectWallet(wallet.name)}
                  style={{ cursor: 'pointer' }}
                >
                  <ListItemAvatar
                    sx={{
                      mx: 1,
                    }}
                  >
                    <div>
                      <Avatar alt="WalletIcon" src={wallet.image} />
                    </div>
                  </ListItemAvatar>
                  <ListItemText
                    primary={wallet.name}
                    primaryTypographyProps={{
                      fontSize: '30px',
                      fontWeight: 'bold',
                    }}
                  />
                </ListItem>
              </React.Fragment>
            ))}
        </List>
        {/* <Divider>SOCIALS</Divider>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Button
            onClick={() => {
              window.location.href =
                'https://discord.com/api/oauth2/authorize?client_id=1107287420673720440&redirect_uri=http%3A%2F%2Flocalhost%3A3000&response_type=code&scope=identify%20guilds%20guilds.members.read';
            }}
            variant="contained"
            style={{
              fontSize: '18px',
              backgroundColor: '#7289DA',
              maxWidth: '80%',
              marginTop: 5,
            }}
            sx={{
              mb: 2,
            }}
          >
            <FaDiscord
              style={{
                fontSize: '24px',
                color: 'white',
                marginRight: 10,
              }}
            />
            Connect to Discord
          </Button>
        </div> */}
      </Dialog>
    </React.Fragment>
  );
};

export default Navbar;
