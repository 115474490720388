import {
  Container,
  Typography,
  SpeedDial,
  SpeedDialIcon,
  SpeedDialAction,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Link,
  Button,
  DialogContentText,
} from '@mui/material';
import { ErrorOutline } from '@mui/icons-material';
import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Navbar from 'components/navbar';
import { screenBreakpoints, unityConfigs } from 'configs/app-config.js';
import ReactGA from 'react-ga';
import axios from 'axios';
import GameContainer from 'components/gameContainer';

const GamePage = () => {
  const smallScreen = window.innerWidth < screenBreakpoints.md;
  ReactGA.initialize('G-RF6770D6JZ');
  // Redux State
  const user = useSelector((state) => state.user);
  const ordinalAddress = (user && user.ordinalAddress) || '';
  const paymentAddress = (user && user.paymentAddress) || '';

  // useStates
  const [isMobile, setIsMobile] = useState(false);
  const [reportDialogOpen, setReportDialogOpen] = useState(false);

  const handleReportDialogOpen = () => {
    setReportDialogOpen(true);
  };

  const handleReportDialogClose = () => {
    setReportDialogOpen(false);
  };

  // Google analytics Page View
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  const dialActions = [
    {
      icon: <ErrorOutline />,
      name: 'Report Problems',
      action: handleReportDialogOpen,
    },
  ];

  // Just in case we need the mobile version even a simple one
  // const isNonMobileScreens = useMediaQuery('(min-width:1000px)');
  return (
    <div>
      <Navbar />
      <GameContainer />
      <Container style={{ color: 'white' }}>
        <br />
        <Typography component="h4" variant="h4" align="center" gutterBottom>
          <span className="montserrat-bold" style={{}}>
            WORLD'S FIRST BTC ORDINALS PLAYABLE GAME
          </span>
        </Typography>
        <Typography variant="h5" align="center" paragraph>
          <span className="montserrat-bold" style={{}}>
            MORE GAMES AND COLLABS COMING SOON...
          </span>
        </Typography>
      </Container>
      {!isMobile && (
        <SpeedDial
          ariaLabel="SpeedDial"
          sx={{
            position: 'fixed',
            bottom: smallScreen ? 20 : 100,
            right: smallScreen ? 10 : 100,
          }}
          icon={<SpeedDialIcon />}
        >
          {dialActions.map((action) => (
            <SpeedDialAction
              key={action.name}
              icon={action.icon}
              tooltipTitle={action.name}
              onClick={action.action}
            />
          ))}
        </SpeedDial>
      )}
      <Dialog open={reportDialogOpen} onClose={handleReportDialogClose}>
        <DialogTitle>Any Issues or Bugs?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please visit our{' '}
            <Link href="https://twitter.com/OrdinalsPlay">Twitter</Link> and DM
            us. We will listen to your feedbacks. Thank you so much.
          </DialogContentText>
          {/* <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Email Address"
            type="email"
            fullWidth
            variant="standard"
          /> */}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleReportDialogClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default GamePage;
