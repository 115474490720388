import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { serverEnvironment } from 'configs/app-config.js';
import { useDispatch, useSelector } from 'react-redux';
import { setLogin, setDiscord } from 'state';

const DiscordRedirectPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const ordinalAddress = (user && user.ordinalAddress) || ''; //useSelector((state) => state.user.ordinalAddress);

  const urlSearchParams = new URLSearchParams(window.location.search);
  const code = urlSearchParams.get('code');

  async function discordLogin() {
    await fetch(`${serverEnvironment}/auth/discord_login`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        ordinalAddress,
        code,
      }),
    })
      .then(async (data) => {
        const response = await data.json();
        if (response.discord) {
          dispatch(setDiscord({ discord: response.discord }));
        }
        if (response.user) {
          dispatch(setLogin({ user: response.user }));
        }

        const discordFromEventId = localStorage.getItem('discordFromEventId');
        if(discordFromEventId){
            navigate(`/event/${discordFromEventId}`);
        }else {
            navigate(`/`);
        }
      })
      .catch((err) => console.error(err));
  }

  useEffect(() => {
    discordLogin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div></div>;
};

export default DiscordRedirectPage;
