import React, { useState, useEffect } from 'react';
import { Container, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { serverEnvironment } from 'configs/app-config.js';
import { useDispatch, useSelector } from 'react-redux';
import { setLogin, setDiscord } from 'state';
import Navbar from 'components/navbar';

const DiscordBotPage = () => {
  const userAgent = navigator.userAgent.toLowerCase();
  const isMobileDevice = /mobile/i.test(userAgent);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const ordinalAddress = (user && user.ordinalAddress) || ''; //useSelector((state) => state.user.ordinalAddress);

  return (
    <div>
      <Navbar />
      <Container style={{ color: 'white' }} align="center">
        <Typography component="h4" variant="h4" align="center" gutterBottom>
          <span className="montserrat-bold">
            1. Start community game event with Ordinals Play Discord App
          </span>
        </Typography>
        <img
          src={`/assets/discord/demo1_1.png`}
          alt="demo1"
          style={{ maxWidth: isMobileDevice ? '80vw' : '400px' }}
        />
        <br />
        <Typography component="h4" variant="h4" align="center" gutterBottom>
          <span className="montserrat-bold" style={{}}>
            2. Select game and collection to play
          </span>
        </Typography>
        <div>
          <img
            src={`/assets/discord/demo2.png`}
            alt="demo2"
            style={{
              maxWidth: isMobileDevice ? '80vw' : '400px',
              marginRight: isMobileDevice ? 0 : 20,
            }}
          />
          <img
            src={`/assets/discord/demo3.png`}
            alt="demo3"
            style={{ maxWidth: isMobileDevice ? '80vw' : '400px' }}
          />
        </div>
        <Typography component="h4" variant="h4" align="center" gutterBottom>
          <span className="montserrat-bold" style={{}}>
            3. Event Link is generated
          </span>
        </Typography>
        <div>
          <img
            src={`/assets/discord/demo4.png`}
            alt="demo2"
            style={{
              maxWidth: isMobileDevice ? '80vw' : '400px',
              marginRight: 20,
            }}
          />
        </div>
        <br />
        <Typography component="h4" variant="h4" align="center" gutterBottom>
          <span className="montserrat-bold" style={{}}>
            3. Play selected game with selected ordinal collection
          </span>
        </Typography>
        <div>
          <img
            src={`/assets/discord/demo5.png`}
            alt="demo2"
            style={{
              maxWidth: isMobileDevice ? '80vw' : '800px',
              marginRight: 20,
            }}
          />
        </div>
        <br />
        <Typography component="h4" variant="h4" align="center" gutterBottom>
          <span className="montserrat-bold" style={{}}>
            4. Finish the game and check the winners
          </span>
        </Typography>
        <div>
          <img
            src={`/assets/discord/demo6.png`}
            alt="demo2"
            style={{
              maxWidth: isMobileDevice ? '80vw' : '400px',
              marginRight: 20,
            }}
          />
        </div>
        <br />

        <Typography component="h4" variant="h4" align="center" gutterBottom>
          <span className="montserrat-bold" style={{}}>
            WORLD'S FIRST BTC ORDINALS PLAYABLE GAME
          </span>
        </Typography>
        <Typography variant="h5" align="center" paragraph>
          <span className="montserrat-bold" style={{}}>
            MORE GAMES AND COLLABS COMING SOON...
          </span>
        </Typography>
      </Container>
    </div>
  );
};

export default DiscordBotPage;
