import {
  Dialog,
  DialogTitle,
  DialogContent,
  Divider,
  DialogContentText,
  DialogActions,
  Button,
} from '@mui/material';
import React, { useState, useEffect } from 'react';
export default function WarningDialog(props) {
  const [openDialog, setOpenDialog] = useState(false);
  const handleCloseDialog = (event, newValue) => {
    props.onCloseWarningDialog();
    setOpenDialog(false)
  };

  useEffect(() => {
    setOpenDialog(props.open);
  }, [props.open, props.selected]);

  return (
    <Dialog open={openDialog} disableEscapeKeyDown>
      <DialogTitle style={{ maxWidth: 1000 }}>
        Discord Bot In Upgrade
      </DialogTitle>
      <Divider />
      <DialogContent
        style={{
          textAlign: 'center',
        }}
      >
        <DialogContentText>
          We are upgrading our Discord bot. It will be easier and faster to use
          with more games! Please come back in few days!
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button
          variant="outlined"
          color="error"
          onClick={() => handleCloseDialog(false)}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
