import {
  Box,
  Typography,
  Avatar,
  Container,
  ListItem,
  List,
  Divider,
  ListItemText,
  ListItemAvatar,
  Chip,
  Grid,
  Button,
  TextField,
} from '@mui/material';
import { Diamond, AccountCircle } from '@mui/icons-material';
import { useNavigate, useParams } from 'react-router-dom';
import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  serverEnvironment,
  defaultInscriptionIds,
  defaultCharacters,
} from 'configs/app-config.js';
import { collectionLinks } from 'configs/collectionData.js';
import { collectionLabels } from 'configs/collectionLabels';
import { eventShareOnTwitter } from '../../util/functions';
import Navbar from 'components/navbar';
import { setUserName } from 'state';
import ReactGA from 'react-ga';

const PublicLeaderboardPage = () => {
  const dispatch = useDispatch();
  ReactGA.initialize('G-RF6770D6JZ');
  // Redux State
  const user = useSelector((state) => state.user);
  const ordinalAddress = user && user.ordinalAddress;
  const [mostActiveCollections, setMostActiveCollections] = useState([]);
  const [highestScoreCollections, setHighestScoreCollections] = useState([]);
  const [mostPlayed, setMostPlayed] = useState([]);
  const [inputValue, setInputValue] = useState(user ? user.userName : null);
  const navigate = useNavigate();
  const userAgent = navigator.userAgent.toLowerCase();
  const isMobileDevice = /mobile/i.test(userAgent);
  const allCollectionlabels = collectionLabels();

  function sortByScoreAndDate(a, b) {
    const aReference = a.score || a.count;
    const bReference = b.score || b.count;
    if (aReference === bReference) {
      // If scores are equal, sort by date in ascending order (earlier first)
      return new Date(a.createdAt) - new Date(b.createdAt);
    }
    // Sort by score in descending order (higher first)
    return bReference - aReference;
  }

  const fetchHistoriesWithHighestScores = async () => {
    try {
      const response = await fetch(`${serverEnvironment}/play/scores/event`);
      const data = await response.json();

      let mostActiveArray = Object.entries(data.collections).map(
        ([key, value]) => ({ name: key, ...value })
      );

      let highestArray = Object.entries(data.collections).map(
        ([key, value]) => ({ name: key, ...value })
      );

      // Sort the array by frequency in descending order
      mostActiveArray.sort((a, b) => b.frequency - a.frequency);
      mostActiveArray = mostActiveArray.slice(0, 10);
      setMostActiveCollections(mostActiveArray);

      highestArray.sort((a, b) => b.highestScore - a.highestScore);
      highestArray = highestArray.slice(0, 10);
      setHighestScoreCollections(highestArray);
    } catch (err) {
      console.error(err);
    }
  };

  // async function fetchHistoriesWithHighestScores(gameName) {
  //   await fetch(`${serverEnvironment}/play/scores/event`, {
  //     method: 'POST',
  //     headers: { 'Content-Type': 'application/json' },
  //     body: JSON.stringify({ gameName }),
  //   })
  //     .then(async (data) => {
  //       const response = await data.json();
  //       const sortedScores = data.topScores.sort(sortByScoreAndDate);

  //       console.log("sortedScores", sortedScores)
  //       setTopScores(sortedScores);
  //     })
  //     .catch((err) => console.error(err));
  // }

  // Google analytics Page View
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
    fetchHistoriesWithHighestScores();
  }, []);

  // // Manual input of discord handle
  // const handleChange = (evt) => {
  //   console.log('evt.target.value', evt.target.value);
  //   setInputValue(evt.target.value);
  // };

  // // Manual input of discord handle
  // const saveUserName = (evt) => {
  //   try {
  //     dispatch(setUserName({ userName: inputValue }));
  //   } catch (error) {
  //     console.log('error', error);
  //   }
  //   // setInputValue(evt.target.value);
  // };

  // Just in case we need the mobile version even a simple one
  // const isNonMobileScreens = useMediaQuery('(min-width:1000px)');
  return (
    <div>
      <Navbar />
      <br />
      <Container style={{ maxWidth: isMobileDevice ? '100%' : '50vw' }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Typography variant="h5" align="center" paragraph>
              <span className="montserrat-bold" style={{}}>
                MOST ACTIVE COLLECTIONS
              </span>
            </Typography>
            <Box sx={{ flexGrow: 1 }}>
              <List style={{ backgroundColor: `rgb(255, 255, 255, 0.9)` }}>
                {mostActiveCollections &&
                  mostActiveCollections.map((history, index) => (
                    <React.Fragment key={index}>
                      <ListItem sx={{ pr: 3.5 }}>
                        {index < 3 && (
                          <Diamond
                            style={{
                              color: 'red',
                              marginLeft: isMobileDevice ? 0 : 10,
                              width: isMobileDevice ? 15 : '',
                            }}
                          />
                        )}
                        <Chip
                          sx={{
                            mx: 1,
                          }}
                          label={index + 1}
                          color="primary"
                        />
                        <ListItemAvatar
                          sx={{
                            mx: 1,
                          }}
                        >
                          <div
                            id={`${allCollectionlabels[history.name]}-${
                              history.frequency
                            }`}
                          >
                            <Avatar
                              sx={{
                                height: 60,
                                width: 60,
                              }}
                              alt={`${history.collectionName}`}
                              src={
                                history.name &&
                                collectionLinks[history.name] &&
                                collectionLinks[history.name].image
                                  ? collectionLinks[history.name].image
                                  : history.name &&
                                    defaultCharacters[history.name]
                                  ? `/assets/collections/${history.name}/${
                                      defaultCharacters[history.name][0]
                                    }.png`
                                  : ''
                              }
                            />
                          </div>
                        </ListItemAvatar>
                        <ListItemText
                          primary={`${allCollectionlabels[history.name]} - ${
                            history.frequency
                          } Plays`}
                          primaryTypographyProps={{
                            fontSize: '20px',
                            fontWeight: 'bold',
                          }}
                        />
                        {collectionLinks[history.name] &&
                        collectionLinks[history.name].me ? (
                          <img
                            src="../assets/magic_eden_icon.png"
                            style={{
                              color: 'red',
                              cursor: 'pointer',
                              maxWidth: '25px',
                            }}
                            alt="me"
                            onClick={() => {
                              window.open(
                                collectionLinks[history.name].me,
                                '_blank'
                              );
                            }}
                          />
                        ) : collectionLinks[history.name] &&
                          collectionLinks[history.name].ow ? (
                          <img
                            src="../assets/ow_icon.svg"
                            style={{
                              color: 'red',
                              cursor: 'pointer',
                              maxWidth: '25px',
                            }}
                            alt="ow"
                            onClick={() => {
                              window.open(
                                collectionLinks[history.name].ow,
                                '_blank'
                              );
                            }}
                          />
                        ) : collectionLinks[history.name] &&
                          collectionLinks[history.name].os ? (
                          <img
                            src="../assets/ord_swap_logo.jpeg"
                            style={{
                              color: 'red',
                              cursor: 'pointer',
                              maxWidth: '25px',
                            }}
                            alt="os"
                            onClick={() => {
                              window.open(
                                collectionLinks[history.name].os,
                                '_blank'
                              );
                            }}
                          />
                        ) : collectionLinks[history.name] &&
                          collectionLinks[history.name].opensea ? (
                          <img
                            src="../assets/opensea_logo.png"
                            style={{
                              color: 'red',
                              cursor: 'pointer',
                              maxWidth: '25px',
                            }}
                            alt="opensea"
                            onClick={() => {
                              window.open(
                                collectionLinks[history.name].opensea,
                                '_blank'
                              );
                            }}
                          />
                        ) : collectionLinks[history.name] &&
                          collectionLinks[history.name].ordzaar ? (
                          <img
                            src="../assets/ordzaar_logo.png"
                            style={{
                              color: 'red',
                              cursor: 'pointer',
                              maxWidth: '25px',
                            }}
                            alt="ordzaar"
                            onClick={() => {
                              window.open(
                                collectionLinks[history.name].ordzaar,
                                '_blank'
                              );
                            }}
                          />
                        ) : (
                          <img
                            src="../assets/twitter_color_resized.png"
                            style={{
                              color: 'red',
                              cursor: 'pointer',
                              maxWidth: '25px',
                            }}
                            alt="twitter"
                            onClick={() => {
                              window.open(
                                collectionLinks[history.name].twitter,
                                '_blank'
                              );
                            }}
                          />
                        )}
                      </ListItem>
                      <Divider />
                    </React.Fragment>
                  ))}
              </List>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="h5" align="center" paragraph>
              <span className="montserrat-bold" style={{}}>
                HIGHEST SCORE COLLECTIONS
              </span>
            </Typography>
            <Box sx={{ flexGrow: 1 }}>
              <List style={{ backgroundColor: `rgb(255, 255, 255, 0.9)` }}>
                {highestScoreCollections &&
                  highestScoreCollections.map((history, index) => (
                    <React.Fragment key={index}>
                      <ListItem sx={{ pr: 3.5 }}>
                        {index < 3 && (
                          <Diamond
                            style={{
                              color: 'red',
                              marginLeft: isMobileDevice ? 0 : 10,
                              width: isMobileDevice ? 15 : '',
                            }}
                          />
                        )}
                        <Chip
                          sx={{
                            mx: 1,
                          }}
                          label={index + 1}
                          color="primary"
                        />
                        <ListItemAvatar
                          sx={{
                            mx: 1,
                          }}
                        >
                          <div
                            id={`${allCollectionlabels[history.name]}-${
                              history.score
                            }`}
                          >
                            <Avatar
                              sx={{
                                height: 60,
                                width: 60,
                              }}
                              alt={`${history.collectionName}`}
                              src={
                                history.name &&
                                collectionLinks[history.name] &&
                                collectionLinks[history.name].image
                                  ? collectionLinks[history.name].image
                                  : history.name &&
                                    defaultCharacters[history.name]
                                  ? `/assets/collections/${history.name}/${
                                      defaultCharacters[history.name][0]
                                    }.png`
                                  : ''
                              }
                            />
                          </div>
                        </ListItemAvatar>
                        <ListItemText
                          primary={`${allCollectionlabels[history.name]} - ${
                            history.highestScore
                          } pts`}
                          primaryTypographyProps={{
                            fontSize: '20px',
                            fontWeight: 'bold',
                          }}
                        />
                        {collectionLinks[history.name] &&
                        collectionLinks[history.name].me ? (
                          <img
                            src="../assets/magic_eden_icon.png"
                            style={{
                              color: 'red',
                              cursor: 'pointer',
                              maxWidth: '25px',
                            }}
                            alt="me"
                            onClick={() => {
                              window.open(
                                collectionLinks[history.name].me,
                                '_blank'
                              );
                            }}
                          />
                        ) : collectionLinks[history.name] &&
                          collectionLinks[history.name].ow ? (
                          <img
                            src="../assets/ow_icon.svg"
                            style={{
                              color: 'red',
                              cursor: 'pointer',
                              maxWidth: '25px',
                            }}
                            alt="ow"
                            onClick={() => {
                              window.open(
                                collectionLinks[history.name].ow,
                                '_blank'
                              );
                            }}
                          />
                        ) : collectionLinks[history.name] &&
                          collectionLinks[history.name].os ? (
                          <img
                            src="../assets/ord_swap_logo.jpeg"
                            style={{
                              color: 'red',
                              cursor: 'pointer',
                              maxWidth: '25px',
                            }}
                            alt="os"
                            onClick={() => {
                              window.open(
                                collectionLinks[history.name].os,
                                '_blank'
                              );
                            }}
                          />
                        ) : collectionLinks[history.name] &&
                          collectionLinks[history.name].opensea ? (
                          <img
                            src="../assets/opensea_logo.png"
                            style={{
                              color: 'red',
                              cursor: 'pointer',
                              maxWidth: '25px',
                            }}
                            alt="opensea"
                            onClick={() => {
                              window.open(
                                collectionLinks[history.name].opensea,
                                '_blank'
                              );
                            }}
                          />
                        ) : collectionLinks[history.name] &&
                          collectionLinks[history.name].ordzaar ? (
                          <img
                            src="../assets/ordzaar_logo.png"
                            style={{
                              color: 'red',
                              cursor: 'pointer',
                              maxWidth: '25px',
                            }}
                            alt="ordzaar"
                            onClick={() => {
                              window.open(
                                collectionLinks[history.name].ordzaar,
                                '_blank'
                              );
                            }}
                          />
                        ) : (
                          <img
                            src="../assets/twitter_color_resized.png"
                            style={{
                              color: 'red',
                              cursor: 'pointer',
                              maxWidth: '25px',
                            }}
                            alt="twitter"
                            onClick={() => {
                              window.open(
                                collectionLinks[history.name].twitter,
                                '_blank'
                              );
                            }}
                          />
                        )}
                      </ListItem>
                      <Divider />
                    </React.Fragment>
                  ))}
              </List>
            </Box>
          </Grid>
        </Grid>
        <div>* The leaderboards get updated every week</div>
      </Container>

      <Container style={{ color: 'white' }}>
        <br />
        <Typography component="h4" variant="h4" align="center" gutterBottom>
          <span className="montserrat-bold" style={{}}>
            WORLD'S FIRST BTC ORDINALS PLAYABLE GAME
          </span>
        </Typography>
        <Typography variant="h5" align="center" paragraph>
          <span className="montserrat-bold" style={{}}>
            MORE GAMES AND COLLABS COMING SOON...
          </span>
        </Typography>
      </Container>
    </div>
  );
};

export default PublicLeaderboardPage;
