// React Modules
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setLogin } from 'state';
// Material UI
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from '@mui/material';
import {
  ExpandMore as ExpandMoreIcon,
  CheckCircle as CheckCircleIcon,
} from '@mui/icons-material';
// Npm modules
import Draggable from 'react-draggable';
import InfiniteScroll from 'react-infinite-scroll-component';
import ReactLoading from 'react-loading';
import { ref, getDownloadURL } from 'firebase/storage';

// Export within the project
import { processWalletAssetsOrdbit } from '../../util/functions';
import {
  defaultCharacters,
  serverEnvironment,
  defaultSelected,
} from 'configs/app-config.js';
import { allCollections } from 'configs/collections.js';
import { storage } from 'configs/firebase-configs';

// components
import TabsBox from 'components/TabsBox';
const userAgent = navigator.userAgent.toLowerCase();
const isMobileDevice = /mobile/i.test(userAgent);
function PaperComponent(props, ref) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper
        {...props}
        ref={ref}
        style={{ minWidth: isMobileDevice ? 0 : 700 }}
      />
    </Draggable>
  );
}
const DraggablePaper = React.forwardRef(PaperComponent);

const isOlderThan30Minutes = (updatedAt) => {
  if (!updatedAt) return true;
  const now = new Date();
  const updatedAtDate = new Date(updatedAt);
  const diff = now.getTime() - updatedAtDate.getTime();
  const minutes = Math.floor(diff / 60000); // Calculate the difference in minutes

  return minutes >= 30;
};

export default function CharacterSelectDialog(props) {
  const dispatch = useDispatch();
  // let collectionList = allCollections();
  const [openDialog, setOpenDialog] = useState(false);
  const [firstAccordion, setFirstAccordion] = useState(true);
  const [lockedImages, setLockedImages] = useState([]);
  const [unlockedImages, setUnlockedImages] = useState(defaultCharacters);
  const [currentLocked, setCurrentLocked] = useState(0);
  const [loading, setLoading] = useState(false);
  const [resetted, setResetted] = useState(false);
  const [collectionList, setCollectionList] = useState(allCollections());
  const [selectedCollection, setSelectedCollection] = useState(
    collectionList[defaultSelected.collection.order]
  );
  const [loadedListings, setLoadedListings] = useState([]);
  const user = useSelector((state) => state.user);
  const ordinalAddress = user && user.ordinalAddress;
  const ethAddress = user && user.ethAddress;
  const walletAddress = ordinalAddress || ethAddress;
  const infiniteScrollRef = useRef(null);
  const cleanupInfiniteScroll = useCallback(() => {
    if (infiniteScrollRef.current) {
      infiniteScrollRef.current._destroy();
    }
  }, []);

  useEffect(() => {
    return () => {
      cleanupInfiniteScroll();
    };
  }, [cleanupInfiniteScroll]);

  useEffect(() => {
    if (props.eventCollection && props.eventCollection !== 'all') {
      switch (props.eventCollection) {
        case 'dogepunks':
          collectionList[0].order = 0;
          setSelectedCollection(collectionList[0]);
          setCollectionList([collectionList[0]]);
          break;
        case 'bitcoinpunks':
          collectionList[1].order = 0;
          setSelectedCollection(collectionList[1]);
          setCollectionList([collectionList[1]]);
          break;
        case 'ordinalrobots':
          collectionList[2].order = 0;
          setSelectedCollection(collectionList[2]);
          setCollectionList([collectionList[2]]);
          break;
        case 'bitcoinbandits':
          collectionList[3].order = 0;
          setSelectedCollection(collectionList[3]);
          setCollectionList([collectionList[3]]);
          break;
        case 'bitcoinbulls':
          collectionList[4].order = 0;
          setSelectedCollection(collectionList[4]);
          setCollectionList([collectionList[4]]);
          break;
        case 'ordibots':
          collectionList[5].order = 0;
          setSelectedCollection(collectionList[5]);
          setCollectionList([collectionList[5]]);
          break;
        case 'ordiblocks':
          collectionList[6].order = 0;
          setSelectedCollection(collectionList[6]);
          setCollectionList([collectionList[6]]);
          break;
        case 'ordinaldragons':
          collectionList[7].order = 0;
          setSelectedCollection(collectionList[7]);
          setCollectionList([collectionList[7]]);
          break;
        case 'ordinaleggs':
          collectionList[8].order = 0;
          setSelectedCollection(collectionList[8]);
          setCollectionList([collectionList[8]]);
          break;
        case 'toxinstemplar':
          collectionList[9].order = 0;
          setSelectedCollection(collectionList[9]);
          setCollectionList([collectionList[9]]);
          break;
        case 'satoshit':
          collectionList[10].order = 0;
          setSelectedCollection(collectionList[10]);
          setCollectionList([collectionList[10]]);
          break;
        case 'bitcoinchimps':
          collectionList[11].order = 0;
          setSelectedCollection(collectionList[11]);
          setCollectionList([collectionList[11]]);
          break;
        case 'bapc':
          collectionList[12].order = 0;
          setSelectedCollection(collectionList[12]);
          setCollectionList([collectionList[12]]);
          break;
        case 'pomb':
          collectionList[13].order = 0;
          setSelectedCollection(collectionList[13]);
          setCollectionList([collectionList[13]]);
          break;
        case 'lazysats':
          collectionList[14].order = 0;
          setSelectedCollection(collectionList[14]);
          setCollectionList([collectionList[14]]);
          break;
        case 'ordinalunis':
          collectionList[15].order = 0;
          setSelectedCollection(collectionList[15]);
          setCollectionList([collectionList[15]]);
          break;
        case 'anons':
          collectionList[16].order = 0;
          setSelectedCollection(collectionList[16]);
          setCollectionList([collectionList[16]]);
          break;
        case 'ordinalfomojis':
          collectionList[17].order = 0;
          setSelectedCollection(collectionList[17]);
          setCollectionList([collectionList[17]]);
          break;
        case 'degenape':
          collectionList[18].order = 0;
          setSelectedCollection(collectionList[18]);
          setCollectionList([collectionList[18]]);
          break;
        case 'taprootcows':
          collectionList[19].order = 0;
          setSelectedCollection(collectionList[19]);
          setCollectionList([collectionList[19]]);
          break;
        case 'sloth':
          collectionList[20].order = 0;
          setSelectedCollection(collectionList[20]);
          setCollectionList([collectionList[20]]);
          break;
        case 'omc':
          collectionList[21].order = 0;
          setSelectedCollection(collectionList[21]);
          setCollectionList([collectionList[21]]);
          break;
        case 'ordinalmages':
          collectionList[22].order = 0;
          setSelectedCollection(collectionList[22]);
          setCollectionList([collectionList[22]]);
          break;
        case 'inscribables':
          collectionList[23].order = 0;
          setSelectedCollection(collectionList[23]);
          setCollectionList([collectionList[23]]);
          break;
        case 'btcwhales':
          collectionList[24].order = 0;
          setSelectedCollection(collectionList[24]);
          setCollectionList([collectionList[24]]);
          break;
        case 'pioneers':
          collectionList[25].order = 0;
          setSelectedCollection(collectionList[25]);
          setCollectionList([collectionList[25]]);
          break;
        case 'npc':
          collectionList[26].order = 0;
          setSelectedCollection(collectionList[26]);
          setCollectionList([collectionList[26]]);
          break;
        case 'aperunners':
          collectionList[27].order = 0;
          setSelectedCollection(collectionList[27]);
          setCollectionList([collectionList[27]]);
          break;
        case 'cpc':
          collectionList[28].order = 0;
          setSelectedCollection(collectionList[28]);
          setCollectionList([collectionList[28]]);
          break;
        case 'bapcgenesis':
          collectionList[29].order = 0;
          setSelectedCollection(collectionList[29]);
          setCollectionList([collectionList[29]]);
          break;
        case 'tribeordinals':
          collectionList[30].order = 0;
          setSelectedCollection(collectionList[30]);
          setCollectionList([collectionList[30]]);
          break;
        case 'tribeodyssey':
          collectionList[31].order = 0;
          setSelectedCollection(collectionList[31]);
          setCollectionList([collectionList[31]]);
          break;
        case 'skelly':
          collectionList[32].order = 0;
          setSelectedCollection(collectionList[32]);
          setCollectionList([collectionList[32]]);
          break;
        case 'almc':
          collectionList[33].order = 0;
          setSelectedCollection(collectionList[33]);
          setCollectionList([collectionList[33]]);
          break;
        case 'tinyminotaurs':
          collectionList[34].order = 0;
          setSelectedCollection(collectionList[34]);
          setCollectionList([collectionList[34]]);
          break;
        case 'bob':
          collectionList[35].order = 0;
          setSelectedCollection(collectionList[35]);
          setCollectionList([collectionList[35]]);
          break;
        case 'psychlops':
          collectionList[36].order = 0;
          setSelectedCollection(collectionList[36]);
          setCollectionList([collectionList[36]]);
          break;
        case 'btcbuds':
          collectionList[37].order = 0;
          setSelectedCollection(collectionList[37]);
          setCollectionList([collectionList[37]]);
          break;
        case 'taruguitos':
          collectionList[38].order = 0;
          setSelectedCollection(collectionList[38]);
          setCollectionList([collectionList[38]]);
          break;
        case 'clownmigos':
          collectionList[39].order = 0;
          setSelectedCollection(collectionList[39]);
          setCollectionList([collectionList[39]]);
          break;
        case 'toshiorder':
          collectionList[40].order = 0;
          setSelectedCollection(collectionList[40]);
          setCollectionList([collectionList[40]]);
          break;
        default:
          break;
      }
    }
  }, [props.eventCollection]);

  // Get inscriptions listed on OW
  const getListedInscriptions = async () => {
    if (selectedCollection.name !== 'bitcoinbandits') {
      await getDownloadURL(ref(storage, `api/${selectedCollection.name}.json`))
        .then(async (url) => {
          const response = await fetch(url);

          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const data = await response.json();
          setLockedImages(data);
        })
        .catch((error) => {
          console.log('error fetching listings', error);
        });
    }
  };

  // Get inscriptions from OW by wallet address
  const getWalletAssets = async () => {
    const chain = !!ethAddress;
    let assets;
    if (isOlderThan30Minutes(user.assetUpdated)) {
      setLoading(true);
      await fetch(`${serverEnvironment}/play/wallet/${walletAddress}`)
        .then(async (data) => {
          // From API
          const response = await data.json();
          assets = processWalletAssetsOrdbit(
            response.inscriptions,
            chain ? 'eth' : 'btc'
          );
          // Set images
          setUnlockedImages(assets);
          // Loading finished
          setLoading(false);

          await fetch(`${serverEnvironment}/auth/assets`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
              walletAddress,
              assets: response.inscriptions,
            }),
          })
            .then(async (data) => {
              const response = await data.json();
              dispatch(setLogin({ user: response.user }));
            })
            .catch((err) => console.error(err));
        })
        .catch((err) => console.error(err));
    } else {
      assets = processWalletAssetsOrdbit(user.assets, chain ? 'eth' : 'btc');
      setUnlockedImages(assets);
    }
    setLoading(false);
  };
  // Set Unlocked Images based on wallet Address
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (walletAddress) {
      getWalletAssets();
    } else {
      setUnlockedImages(defaultCharacters);
    }
    setCurrentLocked(0);
  }, [walletAddress]);

  useEffect(() => {
    setOpenDialog(props.open);
  }, [props.open]);

  const handleClose = () => {
    setOpenDialog(false);
    props.onClose();
  };

  const handleSelectImage = (imageName, type) => {
    props.onSelectImage(imageName, type);
  };

  const addMoreImages = () => {
    // if (currentLocked === 0) {
    //   setCurrentLocked(100);
    // } else {
    //   setCurrentLocked((prevCurrentLocked) => prevCurrentLocked + 100);
    // }
  };

  useEffect(() => {
    getListedInscriptions();
  }, [selectedCollection]);

  return (
    <Dialog
      open={openDialog}
      onClose={handleClose}
      PaperComponent={DraggablePaper}
      aria-labelledby="draggable-dialog-title"
    >
      <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
        Select Your Character
      </DialogTitle>
      <DialogContent>
        <TabsBox
          collectionList={collectionList}
          onChangeTabValue={(tabValue) => {
            props.onSelectCollection(collectionList[tabValue]);
            setSelectedCollection(collectionList[tabValue]);
          }}
          selectedCollection={selectedCollection}
        >
          {collectionList &&
            collectionList.map((collection, i) => (
              <React.Fragment key={collection.name}>
                <Accordion
                  elevation={0}
                  expanded={firstAccordion}
                  key={collection.name + '_unlocked'}
                  onChange={() => setFirstAccordion(!firstAccordion)}
                  style={{
                    border: `1px solid #e7e9eb`,
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>
                      <strong>
                        {ordinalAddress ? 'Unlocked' : 'Default Characters'}
                      </strong>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <ImageList
                      sx={{ width: 'max', height: 350 }}
                      cols={
                        unlockedImages[collection.name] &&
                        unlockedImages[collection.name].length < 5
                          ? unlockedImages[collection.name].length
                          : 4
                      }
                    >
                      {loading && (
                        <div>
                          <ReactLoading type="spokes" color="#808080" />
                          <Typography align="center" paragraph>
                            <span className="montserrat-bold">
                              Due to BTC network Congestion, Loading takes
                              longer than usual.
                            </span>
                          </Typography>
                        </div>
                      )}
                      {unlockedImages[collection.name] &&
                        unlockedImages[collection.name].map((item, index) => (
                          <ImageListItem
                            key={index}
                            style={{ maxWidth: '200px' }}
                          >
                            <img
                              src={`/assets/collections/${collection.name}/${item}.png`}
                              style={{
                                border:
                                  props.selected === item
                                    ? '4px solid green'
                                    : 'none',
                                cursor: 'pointer',
                              }}
                              alt={item.title}
                              loading="lazy"
                              onClick={() =>
                                handleSelectImage(item, 'unlocked')
                              }
                            />
                            {props.selected === item && (
                              <CheckCircleIcon
                                style={{
                                  position: 'absolute',
                                  top: 5,
                                  right: 5,
                                  color: 'green',
                                  fontSize: 30,
                                }}
                              />
                            )}
                            <ImageListItemBar
                              title={`${collection.label} #${
                                item.split('_')[1]
                              }`}
                              subtitle={
                                <span>Inscription#: {item.split('_')[2]}</span>
                              }
                              position="below"
                            />
                          </ImageListItem>
                        ))}
                    </ImageList>
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  elevation={0}
                  key={collection.name + '_locked'}
                  style={{
                    border: `1px solid #e7e9eb`,
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                  >
                    <Typography>
                      <strong>Locked</strong>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div
                      id="scrollableDiv"
                      style={{ height: 300, overflow: 'auto' }}
                    >
                      <InfiniteScroll
                        ref={infiniteScrollRef}
                        dataLength={lockedImages.length} // This is an important field to render the next data
                        // dataLength={10000} // This is an important field to render the next data
                        next={addMoreImages}
                        hasMore={false}
                        loader={<h4>Loading...</h4>}
                        endMessage={
                          <p style={{ textAlign: 'center' }}>
                            <b>Yay! You have seen it all</b>
                          </p>
                        }
                        scrollableTarget="scrollableDiv"
                      >
                        {resetted ? (
                          <ReactLoading type="spokes" color="#808080" />
                        ) : (
                          lockedImages &&
                          lockedImages.map((item, index) => (
                            <img
                              src={`/assets/collections/${collection.name}/${item}.png`}
                              alt={item.title}
                              key={index}
                              loading="lazy"
                              style={{
                                width: '100px',
                                filter: 'grayscale(100%)',
                                cursor: 'pointer',
                              }}
                              onError={(e) => {
                                e.target.src = '';
                              }}
                              onClick={() => handleSelectImage(item, 'locked')}
                            />
                          ))
                        )}
                      </InfiniteScroll>
                    </div>
                  </AccordionDetails>
                </Accordion>
              </React.Fragment>
            ))}
        </TabsBox>
      </DialogContent>
      <DialogActions>
        <Button autoFocus variant="contained" onClick={handleClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
