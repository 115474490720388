import { defaultCharacters } from 'configs/app-config.js';
import btcJsonData from './btcJsonUtils';

export const shareOnTwitter = async (history, owned) => {
  try {
    // const imageUrl = `https://ordinals.com/content/${history.inscriptionId}`;
    // let imageUrl = `https://ordinalswallet.com/inscription/${history.inscriptionId}`;
    // let imageUrl = `https://www.ordyssey.com/inscription/${history.inscriptionNumber}`;
    let imageUrl = `https://magiceden.io/ordinals/item-details/${history.inscriptionId}`;
    let supporter;
    let partnerTwitter = "@DogePunksBTC";

    let collectionName = 'DogePunks';
    let marketplace = '@MEonBTC';

    if (history.collectionName === 'bitcoinpunks') {
      collectionName = 'Bitcoin Punks';
      partnerTwitter = "@Bitcoin_Punks_"
    } else if (history.collectionName === 'ordinalrobots') {
      collectionName = 'Ordinal Robots';
      partnerTwitter = "@OrdinalRobots"
    } else if (history.collectionName === 'bitcoinbandits') {
      collectionName = 'Bitcoin Bandits';
      partnerTwitter = '@banditsbtc';
      imageUrl = "https://magiceden.io/ordinals/marketplace/bitcoin-bandits";
    } else if (history.collectionName === 'bitcoinbulls') {
      collectionName = 'Bitcoin Bulls';
      partnerTwitter = '@BitcoinBullsNFT';
    } else if (history.collectionName === 'ordibots') {
      collectionName = 'Ordibots';
      partnerTwitter = '@OrdiBotsClub';
    } else if (history.collectionName === 'ordiblocks') {
      collectionName = 'Ordiblocks';
      partnerTwitter = '@OrdiBlocks721';
    } else if (history.collectionName === 'ordinaldragons') {
      collectionName = 'Ordinal Dragons';
      partnerTwitter = '@DragonOrdinals';
      imageUrl = "https://magiceden.io/ordinals/marketplace/ordinaldragons";
    } else if (history.collectionName === 'ordinaleggs') {
      collectionName = 'Ordinal Eggs';
      partnerTwitter = '@0xTT957 @OrdiBeasts';
      imageUrl = "https://magiceden.io/ordinals/marketplace/ordinals-egg";
    } else if (history.collectionName === 'toxinstemplar') {
      collectionName = 'Toxins Templar';
      partnerTwitter = '@DegenSoul';
    } else if (history.collectionName === 'satoshit') {
      imageUrl = "https://magiceden.io/ordinals/marketplace/satoshits";
      collectionName = 'Satoshit';
      partnerTwitter = '@Satoshits_BTC';
    } else if (history.collectionName === 'bitcoinchimps') {
      imageUrl = "https://magiceden.io/ordinals/marketplace/btc-chimps";
      collectionName = 'Bitcoin Chimps';
      partnerTwitter = '@BitcoinChimps';
    } else if (history.collectionName === 'bapc') {
      collectionName = 'BAPC';
      partnerTwitter = '@BoredApePixelCl';
    } else if (history.collectionName === 'pomb') {
      collectionName = 'Pixelated OMB';
      partnerTwitter = '@PixelatedOmb';
      imageUrl = "https://magiceden.io/ordinals/marketplace/block9_pomb";
    } else if (history.collectionName === 'lazysats') {
      collectionName = 'Lazy Sats';
      partnerTwitter = '@LazySats';
      imageUrl = "https://lazysats.xyz/";
      marketplace = '';
    } else if (history.collectionName === 'ordinalunis') {
      collectionName = 'Ordinal Unis';
      partnerTwitter = '@OrdinalUnis';
      imageUrl = "https://magiceden.io/ordinals/launchpad/ordinal-unis";
    } else if (history.collectionName === 'ordinalfomojis') {
      collectionName = 'Ordinal Fomojis';
      partnerTwitter = '@OrdinalFomojis';
    } else if (history.collectionName === 'anons') {
      collectionName = 'Anons';
      partnerTwitter = '@OrdinalAnons';
    } else if (history.collectionName === 'degenape') {
      collectionName = 'Degen Ape Squad';
      partnerTwitter = '@Degen_Ape_Squad';
      imageUrl = "https://degenapesquad.com/";
      marketplace = '';
    } else if (history.collectionName === 'taprootcows') {
      collectionName = 'Taproot Cows';
      partnerTwitter = '@taprootcowsfam';
      imageUrl = "https://magiceden.io/ordinals/marketplace/taproot-cows";
    } else if (history.collectionName === 'sloth') {
      collectionName = 'SlothsHQ';
      partnerTwitter = '@slothshq';
      imageUrl = "https://www.subber.xyz/slothshq";
      marketplace = '';
    } else if (history.collectionName === 'omc') {
      collectionName = 'Ordinal Monkey Club';
      partnerTwitter = '@Ordinal_Monkey';
      imageUrl = "https://magiceden.io/ordinals/marketplace/omc";
    } else if (history.collectionName === 'ordinalmages') {
      collectionName = 'Ordinal Mages';
      partnerTwitter = '@Ordinal_Mages';
      imageUrl = "https://magiceden.io/ordinals/marketplace/ordinal_mages";
    } else if (history.collectionName === 'inscribables') {
      collectionName = 'Inscribables';
      partnerTwitter = '@inscribablexyz';
      imageUrl = "https://magiceden.io/ordinals/marketplace/scribe_squad";
    } else if (history.collectionName === 'btcwhales') {
      collectionName = 'Bitcoin Whales';
      partnerTwitter = '@Bitcoin_Whales_';
      imageUrl = "https://magiceden.io/ordinals/marketplace/bitcoinwhales";
    } else if (history.collectionName === 'pioneers') {
      collectionName = 'Pioneer';
      partnerTwitter = '@BitcoinSneakers';
      imageUrl = "https://magiceden.io/ordinals/marketplace/pioneers";
    } else if (history.collectionName === 'npc') {
      collectionName = 'NPC Ordinals';
      partnerTwitter = '@NPCOrdinals';
    } else if (history.collectionName === 'aperunners') {
      collectionName = 'Ape Runners';
      partnerTwitter = '@ApeRunnersBTC';
    } else if (history.collectionName === 'cpc') {
      collectionName = 'Cool Punk Cats';
      partnerTwitter = '@CoolPunkCats';
      marketplace = "@opensea"
      imageUrl = `https://opensea.io/assets/ethereum/0xaa0095174f15cf0f71d6c3a050f484ce19eccf50/${history.collectionNumber}`;
    } else if (history.collectionName === 'bapcgenesis') {
      collectionName = 'Bored Ape Pixel Club';
      partnerTwitter = '@BoredApePixelCl';
      marketplace = "@opensea"
      imageUrl = history.inscriptionNumber ? `https://opensea.io/assets/ethereum/0x495f947276749ce646f68ac8c248420045cb7b5e/${history.inscriptionNumber}` : `https://opensea.io/collection/bored-ape-pixel`;
    } else if (history.collectionName === 'tribeordinals') {
      collectionName = 'Tribe Ordinals';
      partnerTwitter = '@tribeodyssey';
    } else if (history.collectionName === 'tribeodyssey') {
      collectionName = 'Tribe Odyssey';
      partnerTwitter = '@tribeodyssey';
      marketplace = "@opensea"
      imageUrl = history.collectionNumber ? `https://opensea.io/assets/ethereum/0x77f649385ca963859693c3d3299d36dfc7324eb9/${history.collectionNumber}` : `https://opensea.io/collection/tribe-odyssey`;
    } else if (history.collectionName === 'btcbabbies') {
      collectionName = 'Bitcoin Babbies';
      partnerTwitter = '@BTCbabbies';
    } else if (history.collectionName === 'skelly') {
      collectionName = 'Skelly Uni';
      partnerTwitter = '@SkellyUni';
      marketplace = "@ordzaar"
      imageUrl = "https://ordzaar.com/marketplace/collections/skellyuni";
    } else if (history.collectionName === 'almc') {
      collectionName = 'ALMCOrdinals';
      partnerTwitter = '@ALMCOrdinals';
      marketplace = ""
      imageUrl = "";
    } else if (history.collectionName === 'tinyminotaurs') {
      collectionName = 'Tiny Minotaurs';
      partnerTwitter = '@TinyMinotaurs';
      marketplace = ""
      imageUrl = "https://inscribenow.io/collections/fdf79c664453a63c";
    } else if (history.collectionName === 'bob') {
      collectionName = 'Bob on Bitcoin';
      partnerTwitter = '@OrdinalBob';
    } else if (history.collectionName === 'psychlops') {
      collectionName = 'BTC Psychlops';
      partnerTwitter = '@psychlopsnft';
      imageUrl = "";
    } else if (history.collectionName === 'btcbuds') {
      collectionName = 'Bitcoin Buds';
      partnerTwitter = '@bestbudsbtc';
    } else if (history.collectionName === 'taruguitos') {
      collectionName = 'The Taruguitos';
      partnerTwitter = '@the_taruguitos';
    } else if (history.collectionName === 'clownmigos') {
      collectionName = 'Clownmigos';
      partnerTwitter = '@Clownmigos';
      marketplace = "@opensea";
      imageUrl = history.collectionNumber ? `https://opensea.io/assets/ethereum/0x7b22f07ad81ee0436972fda5c4e9c61f6a6f727b/${history.collectionNumber}` : `https://opensea.io/collection/clownmigos`;
    } else if (history.collectionName === 'toshiorder') {
      collectionName = 'The Toshi Order';
      partnerTwitter = '@sat_toshis';
    }

    if (owned === 'default') {
      if (collectionName === 'Bitcoin Punks') {
        supporter = '@bl0ckchainsavvy\'s';
      } else if (collectionName === 'Ordibots') {
        supporter = '@holddoteth\'s';
      } else {
        supporter = 'default'
      }
    } else {
      supporter = owned;
    }

    let text = `I just played #${history.gameName} and scored ${history.score} with ${supporter} ${collectionName} #${history.collectionNumber} at @ordinalsplay!

#Bitcoin #BitcoinNFT #BTC #NFTs #OrdinalsPlay ${marketplace} ${partnerTwitter}`;

    if (history.collectionName === 'bitcoinbandits') {
      text = `I just scored ${history.score} with ${supporter} ${collectionName} at @ordinalsplay!

#Bitcoin #BitcoinNFT #BTC #NFTs #OrdinalsPlay #RocketOrdinals ${partnerTwitter}`;
    }
    const url = `https://twitter.com/intent/tweet?text=${encodeURIComponent(text)}&url=${encodeURIComponent(imageUrl)}`;

    window.open(url, '_blank');
  } catch (err) {
    console.error(err);
  }
};

export const eventShareOnTwitter = async (history, owned, type, rank) => {
  try {
    // const imageUrl = `https://ordinals.com/content/${history.inscriptionId}`;
    // const imageUrl = `https://ordinalswallet.com/inscription/${history.inscriptionId}`;
    const imageUrl = `https://magiceden.io/ordinals/marketplace/pixelated-omb`;

    const text = `I'm ranked top ${rank} ${type} of ${history.score || history.count} ${history.count ? 'times' : ''}with ${owned} Pixelated OMB at @ordinalsplay 's Meteordinals!

#Bitcoin #BitcoinNFT #BTC #NFTs #PixelatedOMB #OrdinalsPlay #Meteordinals @MEonBTC @PixelatedOmb`;
    const url = `https://twitter.com/intent/tweet?text=${encodeURIComponent(text)}&url=${encodeURIComponent(imageUrl)}`;

    window.open(url, '_blank');
  } catch (err) {
    console.error(err);
  }
};

export const processWalletAssets = (assets) => {
  const allProjectInscriptions = {};
  allProjectInscriptions['dogepunks'] = [
    'doge_1786_532510_07d4df1c07f73cd374a989734e882cb638fa60a1ec1a5b98138df417c87468cbi0',
    'doge_7464_527363_9b2cfa39a0b4980004084830c65e751b8a8202964b2596f45696df518e61fc9ai0',
    'doge_7215_528838_667083b54c06646962ec1089e8e73ebf9c44d4538dcdf0309740c5dc716e509ai0'
  ];
  allProjectInscriptions['ordinalrobots'] = [
    "ordinalrobots_103_1051654_5011a458a5a3d9ed3ae80ca39f984f51b95eca54621424cabebb4e44c1c2037ai0",
    "ordinalrobots_388_1093899_e79d547d8b516cddfd71b18fab1e5b3400be2ed03725421230ef7ada36ab8b12i0",
  ];
  allProjectInscriptions['bitcoinpunks'] = [
    'bitcoinpunks_3298_17287_03190afb329844abc23145a76025dc8251c9f906d68cb0a86ac9cb9c79aa3f46i0',
    'bitcoinpunks_6781_28487_6ad86e218f9969683437cc411a028a46a59ca1d6df623535383028464ddc5e75i0',
    'bitcoinpunks_9111_23032_9bea7834f04b539348aec776e46da6b9c160dfd5f6f207d575a02eb40332ac37i0'
  ];

  for (const asset of assets) {
    const collectionName = asset.split('_')[0] === 'doge' ? 'dogepunks' : asset.split('_')[0];
    allProjectInscriptions[collectionName].push(asset);
  }

  return allProjectInscriptions;
}

export const processWalletAssetsOrdbit = (assets, chain = 'btc') => {
  const allProjectInscriptions = {};

  for (const [collectionName, images] of Object.entries(defaultCharacters)) {
    allProjectInscriptions[collectionName] = images;
  }

  if (assets) {
    if (chain === 'btc') {
      for (const asset of assets) {
        for (const [collectionName, collectionData] of Object.entries(btcJsonData)) {
          if (collectionData[asset.id]) {
            allProjectInscriptions[collectionName].push(collectionData[asset.id]);
            break;
          }
        }
      }
    } else {
      for (const asset of assets) {
        if (asset.tokenAddress === "0xaa0095174f15cf0f71d6c3a050f484ce19eccf50") {
          allProjectInscriptions['cpc'].push(`cpc_${asset.tokenId}_${asset.tokenId}`);
        }
        else if (asset.tokenAddress === "0x495f947276749ce646f68ac8c248420045cb7b5e" && asset.metadata.name.includes("Bored Ape Pixel Club")) {
          // console.log("asset.metadata.name", asset.metadata.name)
          // console.log("asset.metadata.name.split('#')[1]", asset.metadata.name.split('#')[1])
          allProjectInscriptions['bapcgenesis'].push(`bapcgenesis_${asset.metadata.name.split('#')[1]}_${asset.tokenId}`);
        }
        else if (asset.tokenAddress === "0x77f649385ca963859693c3d3299d36dfc7324eb9" && asset.metadata.name.toLowerCase().includes("tribe")) {
          allProjectInscriptions['tribeodyssey'].push(`tribeodyssey_${asset.tokenId}_${asset.tokenId}`);
        }
      }
    }

  }

  return allProjectInscriptions;
}
