import bitcoinbulls from '../api/btcCollectionIds/bitcoinbulls.json';
import bitcoinpunks from '../api/btcCollectionIds/bitcoinpunks.json';
import dogepunks from '../api/btcCollectionIds/dogepunks.json';
import npc from '../api/btcCollectionIds/npc.json';
import ordinalfomojis from '../api/btcCollectionIds/ordinalfomojis.json';
import ordinalrobots from '../api/btcCollectionIds/ordinalrobots.json';

const btcJsonData = {
    bitcoinbulls,
    bitcoinpunks,
    dogepunks,
    npc,
    ordinalfomojis,
    ordinalrobots,
};

export default btcJsonData;