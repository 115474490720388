export const allCollections = () => {
  const list = [
    {
      order: 0,
      id: 0,
      name: 'dogepunks',
      label: 'DogePunks',
      size: 10000
    },
    {
      order: 1,
      id: 1,
      name: 'bitcoinpunks',
      label: 'Bitcoin Punks',
      size: 10000
    },
    {
      order: 2,
      id: 2,
      name: 'ordinalrobots',
      label: 'Ordinal Robots',
      size: 444
    },
    {
      order: 3,
      id: 3,
      name: 'bitcoinbandits',
      label: 'Bitcoin Bandits',
      size: 1
    },
    {
      order: 4,
      id: 4,
      name: 'bitcoinbulls',
      label: 'Bitcoin Bulls',
      size: 400
    },
    {
      order: 5,
      id: 5,
      name: 'ordibots',
      label: 'Ordibots',
      size: 1000
    },
    {
      order: 6,
      id: 6,
      name: 'ordiblocks',
      label: 'Ordiblocks',
      size: 1
    },
    {
      order: 7,
      id: 7,
      name: 'ordinaldragons',
      label: 'Ordinal Dragons',
      size: 6
    },
    {
      order: 8,
      id: 8,
      name: 'ordinaleggs',
      label: 'Ordinals Egg',
      size: 3
    },
    {
      order: 9,
      id: 9,
      name: 'toxinstemplar',
      label: 'Toxins Templar',
      size: 5
    },
    {
      order: 10,
      id: 10,
      name: 'satoshit',
      label: 'Satoshit',
      size: 11
    },
    {
      order: 11,
      id: 11,
      name: 'bitcoinchimps',
      label: 'Bitcoin Chimps',
      size: 3
    },
    {
      order: 12,
      id: 12,
      name: 'bapc',
      label: 'BAPC',
      size: 3
    },
    {
      order: 13,
      id: 13,
      name: 'pomb',
      label: 'Pixelated OMB',
      size: 5
    },
    {
      order: 14,
      id: 14,
      name: 'lazysats',
      label: 'Lazy Sats',
      size: 3
    },
    {
      order: 15,
      id: 15,
      name: 'ordinalunis',
      label: 'Ordinal Unis',
      size: 3
    },
    {
      order: 16,
      id: 16,
      name: 'anons',
      label: 'Ordinal Anons',
      size: 5
    },
    {
      order: 17,
      id: 17,
      name: 'ordinalfomojis',
      label: 'Ordinal Fomojis',
      size: 100
    },
    {
      order: 18,
      id: 18,
      name: 'degenape',
      label: 'Degen Ape Squad',
      size: 4
    },
    {
      order: 19,
      id: 19,
      name: 'taprootcows',
      label: 'Taproot Cows',
      size: 4
    },
    {
      order: 20,
      id: 20,
      name: 'sloth',
      label: 'SlothsHQ',
      size: 3
    },
    {
      order: 21,
      id: 21,
      name: 'omc',
      label: 'Ordinal Monkey Club',
      size: 7
    },
    {
      order: 22,
      id: 22,
      name: 'ordinalmages',
      label: 'Ordinal Mages',
      size: 3
    },
    {
      order: 23,
      id: 23,
      name: 'inscribables',
      label: 'inscribables',
      size: 7
    },
    {
      order: 24,
      id: 24,
      name: 'btcwhales',
      label: 'Bitcoin Whales',
      size: 5
    },
    {
      order: 25,
      id: 25,
      name: 'pioneers',
      label: 'Pioneers',
      size: 6
    },
    {
      order: 26,
      id: 26,
      name: 'npc',
      label: 'NPC Ordinals',
      size: 9996
    },
    {
      order: 27,
      id: 27,
      name: 'aperunners',
      label: 'Ape runners',
      size: 4
    },
    {
      order: 28,
      id: 28,
      name: 'cpc',
      label: 'Cool Punk cats',
      size: 999
    },
    {
      order: 29,
      id: 29,
      name: 'bapcgenesis',
      label: 'BAPC Genesis',
      size: 501
    },
    {
      order: 30,
      id: 30,
      name: 'tribeordinals',
      label: 'Tribe Ordinals',
      size: 450
    },
    {
      order: 31,
      id: 31,
      name: 'tribeodyssey',
      label: 'Tribe Odyssey',
      size: 9401
    },
    {
      order: 32,
      id: 32,
      name: 'skelly',
      label: 'Skelly Uni',
      size: 3
    },
    {
      order: 33,
      id: 33,
      name: 'almc',
      label: 'Awkward Look Monkey Club',
      size: 7
    },
    {
      order: 34,
      id: 34,
      name: 'tinyminotaurs',
      label: 'Tiny Minotaurs',
      size: 4
    },
    {
      order: 35,
      id: 35,
      name: 'bob',
      label: 'Bob on Bitcoin',
      size: 5
    },
    {
      order: 36,
      id: 36,
      name: 'psychlops',
      label: 'BTC Psychlops',
      size: 5
    },
    {
      order: 37,
      id: 37,
      name: 'btcbuds',
      label: 'Bitcoin Buds',
      size: 1
    },
    {
      order: 38,
      id: 38,
      name: 'taruguitos',
      label: 'The Taruguitos',
      size: 5
    },
    {
      order: 39,
      id: 39,
      name: 'clownmigos',
      label: 'Clownmigos',
      size: 3
    },
    {
      order: 40,
      id: 40,
      name: 'toshiorder',
      label: 'The Toshi Order',
      size: 5
    },
    // {
    //   order: 32,
    // id: 32,
    //   name: 'btcbabbies',
    //   label: 'Bitcoin Babbies',
    //   size: 5000
    // },
    // {
    //   name: 'degods',
    //   label: 'De Gods',
    //   size: 535
    // },
    // {
    //   name: 'bitcoinpunks',
    //   label: 'Bitcoin Punks',
    //   size: 10000
    // },
  ]

  return list;
}